import { BuyOrderPurchaseType } from '@/types/global'

function getBidOrderTypeText(type?: BuyOrderPurchaseType) {
  if (type === ('global' as BuyOrderPurchaseType)) {
    return 'Global'
  }
  if (type === ('attribute' as BuyOrderPurchaseType)) {
    return 'Global Attribute'
  }
  return 'Direct'
}

export default getBidOrderTypeText
