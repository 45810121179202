import { syncWallet } from '@/api/wallet'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export default function useSyncWallets() {
  const queryClient = useQueryClient()

  const syncWalletMutation = useMutation(
    (walletUUIDs: string[]) =>
      Promise.all(walletUUIDs.map((walletUUID) => syncWallet(walletUUID))),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userVerifiedWallets'])
        queryClient.invalidateQueries(['profileExternalNifties'])
      },
    },
  )

  return syncWalletMutation
}
