import useUserVerifiedWallets from '@/hooks/useUserVerifiedWallets'
import { useProfileContext } from '@/providers/ProfileContext'
import { HubbleMenu, Tooltip, truncateString } from '@/elements/src'
import { IconArrowsCircle, IconWalletFilled } from '@hubble/icons'
import { Button } from '@hubble/web'
import useSyncWallets from '@/hooks/useSyncWallets'

interface WalletFilterProps {
  isMobile: boolean
}

export default function WalletFilter({ isMobile }: WalletFilterProps) {
  const {
    isOwnProfile,
    state: { wallet },
    data: { custodialCount, externalCount, walletLabelData },
    updateQuery,
    scrollToTop,
  } = useProfileContext()

  const { data } = useUserVerifiedWallets({
    refetchOnMount: true,
    enabled: isOwnProfile,
  })

  const verifiedWallets = data || []
  const walletSynced = !verifiedWallets.find((w) => w.address === wallet)
    ?.can_sync
  const areAllWalletsSynced = verifiedWallets.every((w) => !w.can_sync)

  const synced =
    wallet === 'combinedWallets' ? areAllWalletsSynced : walletSynced
  const syncWalletsMutation = useSyncWallets()

  const getWalletDropdownLabel = () => {
    if (wallet === 'custodialWallet') {
      return 'Nifty Gateway Wallet'
    }

    if (wallet === 'combinedWallets') {
      return 'All External Wallets'
    }

    return truncateString(wallet || '', 5, 4)
  }

  const genericFilterHandler = (w: string) => {
    scrollToTop(() => {
      updateQuery({
        wallet: w,
        ordering: null,
      })
    })
  }

  const handleSyncWallets = () => {
    const syncingWallets =
      wallet === 'combinedWallets'
        ? verifiedWallets.filter((w) => w.can_sync).map((w) => w.uuid)
        : [verifiedWallets.find((w) => w.address === wallet)?.uuid!]
    syncWalletsMutation.mutate(syncingWallets)
  }

  const individualWalletDropdownOptions = walletLabelData?.map(
    (individualWallet) => {
      return (
        <HubbleMenu.Item
          key={individualWallet.address}
          value={individualWallet.address}
          onClick={() => {
            genericFilterHandler(individualWallet.address)
          }}
        >
          {truncateString(individualWallet.address || '', 5, 4)} (
          {!individualWallet.count ? '--' : individualWallet.count})
        </HubbleMenu.Item>
      )
    },
  )
  const walletDropdownOptions = [
    <HubbleMenu.Item
      key='custodialWallet'
      value='custodialWallet'
      onClick={() => {
        genericFilterHandler('custodialWallet')
      }}
    >
      Nifty Gateway Wallet ({!custodialCount ? '--' : custodialCount})
    </HubbleMenu.Item>,
    ...individualWalletDropdownOptions,
    <HubbleMenu.Item
      key='combinedWallets'
      value='combinedWallets'
      onClick={() => {
        genericFilterHandler('combinedWallets')
      }}
    >
      All External Wallets ({!externalCount ? '--' : externalCount})
    </HubbleMenu.Item>,
  ]
  return (
    <>
      <HubbleMenu
        id='verified-wallet-filter'
        selectedOption={wallet || walletLabelData?.[0]?.address}
        label={getWalletDropdownLabel()}
        endIcon={isMobile ? null : undefined}
        buttonProps={{
          fullWidth: isMobile,
          variant: isMobile ? 'tertiary' : 'secondary',
          startIcon: isMobile ? <IconWalletFilled /> : undefined,
          sx: isMobile
            ? { padding: '8px 16px', whiteSpace: 'nowrap' }
            : undefined,
        }}
      >
        {walletDropdownOptions}
      </HubbleMenu>
      <Tooltip
        placement='top'
        body={
          synced ? (
            <span>
              Wallet already synced. Please wait a bit before resyncing.
            </span>
          ) : (
            <span>
              Press &lsquo;sync&rsquo; if you are not seeing NFTs from this
              wallet in your profile.
            </span>
          )
        }
      >
        <span>
          {isMobile ? (
            <Button.Tertiary
              aria-label='Sync Wallet'
              disabled={synced}
              icon={<IconArrowsCircle />}
              onClick={() => handleSyncWallets()}
            />
          ) : (
            <Button.Secondary
              aria-label='Sync Wallet'
              disabled={synced}
              icon={<IconArrowsCircle />}
              onClick={() => handleSyncWallets()}
            />
          )}
        </span>
      </Tooltip>
    </>
  )
}
