import React from 'react'
import { useRouter } from 'next/navigation'

import { Box } from '@mui/material'

import { web } from '@hubble/tokens'
import { Button } from '@hubble/web'
import {
  IconHeartFilled,
  IconHeartOutlined,
  IconMoreHorizontal,
} from '@hubble/icons'
import { HubbleMenu, HubbleSheet, NiftyAssetProps } from '@/elements/src'

import { useAnalytics } from '@/providers/AnalyticsContext'
import { useCreateTokenWithdrawal } from '@/hooks/useTokenWithdrawal'
import { useProfileContext } from '@/providers/ProfileContext'
import useNiftyDownload from '@/hooks/useNFTDownload'
import useLikeButton from '@/hooks/useLikeButton'

import { getListingUrl } from '@/utils/get-links'
import { ProfileNiftyV2 } from '@/types/global'
import { ProfileData } from '@/types/profile'
import { NIFTY_ITEM_OPTIONS_TEXT } from '../../constants'

interface NftItemOptionsProps {
  asset: NiftyAssetProps
  isMobile?: boolean
  isOwnProfile: boolean
  isThereAnActiveOffer: boolean
  item: ProfileData['items'][0]
  onAcceptBid: () => void
  onCancelListing: () => void
  onCloseMenu: () => void
  onExpandToFullScreen: () => void
  onOpenMenu: () => void
  onTransfer: () => void
}

export default function NftItemOptions({
  asset,
  isMobile,
  isOwnProfile,
  isThereAnActiveOffer,
  item,
  onAcceptBid,
  onCancelListing,
  onCloseMenu,
  onExpandToFullScreen,
  onOpenMenu,
  onTransfer,
}: NftItemOptionsProps) {
  const history = useRouter()
  const { logEvent } = useAnalytics()
  const { createTokenWithdrawal } = useCreateTokenWithdrawal()
  const { setState, updateQuery } = useProfileContext()

  const [showSheet, setShowSheet] = React.useState<boolean>(false)

  const niftyItem = item as ProfileNiftyV2
  const { downloadNifty } = useNiftyDownload(asset.src, item.name)
  const { actualLikeCount, isLiked, handleLikeClick } = useLikeButton({
    nifty: {
      tokenId: niftyItem.tokenId,
      contractAddress: niftyItem.contractAddress,
      name: niftyItem.name,
    },
    likesAmount: niftyItem.likes,
  })

  const sellOrder = niftyItem.sellOrder?.[0]
  const isNiftyListedForSale = !!niftyItem.listing || sellOrder?.currentlyActive

  const renderLikeButton = () => {
    if (!niftyItem.contractAddress || niftyItem.contractAddress === 'none')
      return null

    if (actualLikeCount > 0) {
      return (
        <Button.Secondary
          aria-label='add/remove like'
          className='button--likes number'
          cta={actualLikeCount.toString()}
          onClick={(e) => {
            if (e) handleLikeClick(e)
          }}
          rightElement={isLiked ? <IconHeartFilled /> : <IconHeartOutlined />}
          size='sm'
        />
      )
    }

    return (
      <Button.Secondary
        aria-label='add/remove like'
        className='button--likes icon'
        icon={isLiked ? <IconHeartFilled /> : <IconHeartOutlined />}
        onClick={(e) => {
          if (e) handleLikeClick(e)
        }}
        size='sm'
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}
    >
      {isOwnProfile &&
        (isMobile ? (
          <>
            <Button.Secondary
              aria-label='NFT options'
              className='button--likes icon'
              icon={<IconMoreHorizontal />}
              onClick={(e) => {
                e?.preventDefault()
                setShowSheet(true)
              }}
              size='sm'
            />

            <HubbleSheet
              open={showSheet}
              onOpen={(e) => {
                e?.preventDefault()
                setShowSheet(true)
              }}
              onClose={(e) => {
                e?.preventDefault()
                setShowSheet(false)
              }}
              onClick={(e) => {
                e?.preventDefault()
                setShowSheet(false)
              }}
            >
              <Box
                sx={{
                  mb: 3,
                }}
              >
                {isThereAnActiveOffer && (
                  <>
                    <Box
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          selectedNifty: niftyItem,
                        }))
                        updateQuery({
                          profileView: 'table',
                          wallet: 'custodialWallet',
                        })
                      }}
                      sx={{
                        borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                        py: 2,
                      }}
                    >
                      {NIFTY_ITEM_OPTIONS_TEXT.view}
                    </Box>
                    <Box
                      onClick={() => {
                        setShowSheet(false)
                        onAcceptBid()
                      }}
                      sx={{
                        borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                        py: 2,
                      }}
                    >
                      {NIFTY_ITEM_OPTIONS_TEXT.accept}
                    </Box>
                  </>
                )}
                <Box
                  onClick={() => {
                    if (isNiftyListedForSale) onCancelListing()
                    else
                      history.push(
                        getListingUrl(
                          niftyItem.contractAddress,
                          niftyItem.tokenId,
                        ),
                      )
                  }}
                  sx={{
                    borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                    py: 2,
                  }}
                >
                  {isNiftyListedForSale
                    ? NIFTY_ITEM_OPTIONS_TEXT.unlist
                    : NIFTY_ITEM_OPTIONS_TEXT.list}
                </Box>
                <Box
                  onClick={() => {
                    logEvent?.('Transfer Click', 'Other')
                    onTransfer()
                  }}
                  sx={{
                    borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                    py: 2,
                  }}
                >
                  {NIFTY_ITEM_OPTIONS_TEXT.send}
                </Box>
                <Box
                  onClick={() =>
                    createTokenWithdrawal({
                      contract_address: niftyItem.contractAddress,
                      token_id: niftyItem.tokenId,
                    })
                  }
                  sx={{
                    borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                    py: 2,
                  }}
                >
                  {NIFTY_ITEM_OPTIONS_TEXT.withdraw}
                </Box>
                <Box
                  onClick={(e) => {
                    const event = e as unknown as React.MouseEvent<
                      HTMLButtonElement,
                      MouseEvent
                    >
                    downloadNifty(event)
                  }}
                  sx={{
                    borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                    py: 2,
                  }}
                >
                  {NIFTY_ITEM_OPTIONS_TEXT.download}
                </Box>
                <Box
                  onClick={onExpandToFullScreen}
                  sx={{
                    borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
                    py: 2,
                  }}
                >
                  {NIFTY_ITEM_OPTIONS_TEXT.expand}
                </Box>
              </Box>
            </HubbleSheet>
          </>
        ) : (
          <HubbleMenu
            endIcon={null}
            label={<IconMoreHorizontal />}
            buttonProps={{
              isIconButton: true,
              size: 'small',
              variant: 'invertedPrimary',
            }}
            onOpen={onOpenMenu}
            onClose={onCloseMenu}
          >
            {isThereAnActiveOffer && (
              <>
                <HubbleMenu.Item
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedNifty: niftyItem,
                    }))
                    updateQuery({
                      profileView: 'table',
                      wallet: 'custodialWallet',
                    })
                  }}
                >
                  {NIFTY_ITEM_OPTIONS_TEXT.view}
                </HubbleMenu.Item>
                <HubbleMenu.Item onClick={onAcceptBid}>
                  {NIFTY_ITEM_OPTIONS_TEXT.accept}
                </HubbleMenu.Item>
              </>
            )}
            <HubbleMenu.Item
              onClick={() => {
                if (isNiftyListedForSale) onCancelListing()
                else
                  history.push(
                    getListingUrl(niftyItem.contractAddress, niftyItem.tokenId),
                  )
              }}
            >
              {isNiftyListedForSale
                ? NIFTY_ITEM_OPTIONS_TEXT.unlist
                : NIFTY_ITEM_OPTIONS_TEXT.list}
            </HubbleMenu.Item>
            <HubbleMenu.Item
              onClick={() => {
                onTransfer()
                logEvent?.('Transfer Click', 'Other')
              }}
            >
              {NIFTY_ITEM_OPTIONS_TEXT.send}
            </HubbleMenu.Item>
            <HubbleMenu.Item
              onClick={() => {
                createTokenWithdrawal({
                  contract_address: niftyItem.contractAddress,
                  token_id: niftyItem.tokenId,
                })
              }}
            >
              {NIFTY_ITEM_OPTIONS_TEXT.withdraw}
            </HubbleMenu.Item>
            <HubbleMenu.Item onClick={() => downloadNifty()}>
              {NIFTY_ITEM_OPTIONS_TEXT.download}
            </HubbleMenu.Item>
            <HubbleMenu.Item onClick={onExpandToFullScreen}>
              {NIFTY_ITEM_OPTIONS_TEXT.expand}
            </HubbleMenu.Item>
          </HubbleMenu>
        ))}
      {renderLikeButton()}
    </Box>
  )
}
