import { useMemo } from 'react'

import { Box, Skeleton } from '@mui/material'
import {
  HubbleGrid,
  HubbleGridChild,
  HubbleStatsGrid,
  HubbleStatsGridProps,
} from '@/elements/src'

import { UserNiftyStats } from '@/types/api_types'
import { useUserNiftyStats } from '@/hooks/useUserNiftyStats'

const getItems = (data?: UserNiftyStats): HubbleStatsGridProps['items'] => [
  {
    label: 'Total Cost Basis',
    type: 'money',
    value: data?.totalCost ?? 0,
    size: [2, 4, 3],
    info: 'Sum of what you paid for the NFTs in your collection.',
  },
  {
    label: 'Total Avg. Resale',
    type: 'money',
    value: data?.totalAvgResale ?? 0,
    size: [2, 4, 3],
    info: 'Average price of resale for NFTs in your collection.',
  },
  {
    label: 'Total Floor Price',
    type: 'money',
    value: data?.totalFloor ?? 0,
    size: [2, 4, 3],
    info: 'Sum of the lowest active listing price for the NFTs in your collection.',
  },
  {
    label: 'Total Offers',
    type: 'money',
    value: data?.totalOffers ?? 0,
    size: [2, 4, 3],
    info: 'Sum of the offers for the NFTs in your collection.',
  },
]

const ManageNiftiesStats = () => {
  const { userNiftyStats, isLoading } = useUserNiftyStats()

  const items = useMemo(() => getItems(userNiftyStats), [userNiftyStats])

  const showLoading = isLoading || !userNiftyStats

  return (
    <Box
      sx={{
        mb: 6,
      }}
    >
      <HubbleGrid>
        <HubbleGridChild columns={[4, 8, 10, 8]}>
          {showLoading ? (
            <Skeleton
              variant='rectangular'
              sx={{
                borderRadius: '20px',
                width: '100%',
                height: '67px',
                '@media (max-width: 904px)': { height: '133px' },
              }}
            />
          ) : (
            <HubbleStatsGrid items={items} />
          )}
        </HubbleGridChild>
      </HubbleGrid>
    </Box>
  )
}

export default ManageNiftiesStats
