import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import { colors } from '@/elements/src'

const StyledDetailsContainer = styled(Box)({
  padding: 16,
  borderRadius: 20,
  marginBottom: 24,
  border: `1px solid ${colors.actionGrey}`,
})

interface ManageNiftiesModalDetailContainerProps {
  label: string
  children: React.ReactNode
}

function ManageNiftiesModalDetailContainer({
  label,
  children,
}: ManageNiftiesModalDetailContainerProps) {
  return (
    <StyledDetailsContainer>
      <Typography mb={2} fontWeight={600} fontSize='20px'>
        {label}
      </Typography>
      {children}
    </StyledDetailsContainer>
  )
}

export default ManageNiftiesModalDetailContainer
