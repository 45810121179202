import { useState } from 'react'
import { useCopyToClipboard } from 'react-use'

import { Box, Typography, Tooltip, ClickAwayListener } from '@mui/material'
import { IconCopy } from '@hubble/icons'
import { Button } from '@hubble/web'

interface ManageExternalNiftiesContractCellProps {
  contractAddress: string
}

export default function ManageExternalNiftiesContractCell({
  contractAddress,
}: ManageExternalNiftiesContractCellProps) {
  const [, copyToClipboard] = useCopyToClipboard()

  const [showTooltip, setShowTooltip] = useState(false)

  const clickCopy = () => {
    copyToClipboard(contractAddress)
    setShowTooltip(true)
  }

  const handleTooltipClose = () => setShowTooltip(false)

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'left',
      }}
    >
      <Typography
        component='span'
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {contractAddress}
      </Typography>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={showTooltip}
            placement='top'
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title='Copied!'
          >
            <Button.Tertiary
              aria-label='Copy'
              icon={<IconCopy />}
              onClick={clickCopy}
            />
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Box>
  )
}
