import React from 'react'

interface PolygonLogoProps {
  width?: number
  height?: number
}

function PolygonLogo({ width = 20, height = 20 }: PolygonLogoProps) {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='12' fill='black' />
      <path
        fill='white'
        d='M15.0554 9.91923C14.8339 9.78639 14.5461 9.78639 14.3026 9.91923L12.5756 10.9377L11.4022 11.6019L9.67528 12.6203C9.45387 12.7532 9.16605 12.7532 8.92251 12.6203L7.54982 11.8233C7.32841 11.6905 7.17343 11.4469 7.17343 11.1812V9.60927C7.17343 9.34359 7.30627 9.10004 7.54982 8.9672L8.90037 8.1923C9.12177 8.05945 9.40959 8.05945 9.65314 8.1923L11.0037 8.9672C11.2251 9.10004 11.3801 9.34359 11.3801 9.60927V10.6277L12.5535 9.94137V8.92292C12.5535 8.65724 12.4207 8.4137 12.1771 8.28086L9.67528 6.8196C9.45387 6.68676 9.16605 6.68676 8.92251 6.8196L6.37638 8.28086C6.13284 8.4137 6 8.65724 6 8.92292V11.8676C6 12.1333 6.13284 12.3768 6.37638 12.5096L8.92251 13.9709C9.14391 14.1037 9.43173 14.1037 9.67528 13.9709L11.4022 12.9746L12.5756 12.2882L14.3026 11.2919C14.524 11.1591 14.8118 11.1591 15.0554 11.2919L16.4059 12.0668C16.6273 12.1997 16.7823 12.4432 16.7823 12.7089V14.2809C16.7823 14.5465 16.6494 14.7901 16.4059 14.9229L15.0554 15.72C14.8339 15.8528 14.5461 15.8528 14.3026 15.72L12.952 14.9451C12.7306 14.8122 12.5756 14.5687 12.5756 14.303V13.2845L11.4022 13.9709V14.9893C11.4022 15.255 11.5351 15.4986 11.7786 15.6314L14.3247 17.0927C14.5461 17.2255 14.8339 17.2255 15.0775 17.0927L17.6236 15.6314C17.845 15.4986 18 15.255 18 14.9893V12.0447C18 11.779 17.8672 11.5355 17.6236 11.4026L15.0554 9.91923Z'
      />
    </svg>
  )
}

export default PolygonLogo
