import { Box } from '@mui/material'
import { HubbleBadge, HubbleButton, HubbleTableTitleCell } from '@/elements/src'

import { ProfileNiftyV2 } from '@/types/global'
import { getListingUrl } from '@/utils/get-links'
import formatAmountForDisplay from '@/utils/format-amount-for-display'

import useManageNiftiesOffers from '@/hooks/useManageNiftiesOffers'

interface ManageNiftiesOffersCellProps {
  nifty: ProfileNiftyV2
  isBadge?: boolean
  isBestOffer?: boolean
  isViewButton?: boolean
  isListButton?: boolean
  isNiftyListedForSale?: boolean
  onCancelSale?: () => void
  onSelect?: () => void
}

function ManageNiftiesOffersCell({
  nifty,
  isBadge,
  isBestOffer,
  isViewButton,
  isListButton,
  isNiftyListedForSale,
  onCancelSale,
  onSelect,
}: ManageNiftiesOffersCellProps) {
  const { data, isLoading } = useManageNiftiesOffers(nifty)

  if (isViewButton) {
    const thereIsAnActiveOffer = !!data?.highestOffer
    return !isLoading && thereIsAnActiveOffer ? (
      <Box display='flex' justifyContent='center'>
        <HubbleButton
          size='small'
          type='button'
          variant='primary'
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            onSelect?.()
          }}
        >
          View Offers
        </HubbleButton>
      </Box>
    ) : (
      <></>
    )
  }

  if (isListButton)
    return (
      <Box display='flex' justifyContent='center'>
        <HubbleButton
          size='small'
          openInNewTab
          type='button'
          variant='secondary'
          disabled={isLoading}
          href={
            !isNiftyListedForSale
              ? getListingUrl(nifty.contractAddress, nifty.tokenId)
              : undefined
          }
          onClick={(event) => {
            event.stopPropagation()
            if (isNiftyListedForSale) {
              event.preventDefault()
              onCancelSale?.()
            }
          }}
        >
          {isNiftyListedForSale ? 'Unlist' : 'List'}
        </HubbleButton>
      </Box>
    )

  if (isBadge) {
    const value = data?.totalOffers ?? nifty.stats?.numberOfBids ?? 0
    const label = value === 1 ? 'Offer' : 'Offers'
    return (
      <Box display='flex' justifyContent='flex-end'>
        <HubbleBadge
          color='default'
          label={!isLoading ? `${value} ${label}` : '0 Offers'}
        />
      </Box>
    )
  }

  return (
    <HubbleTableTitleCell
      align='right'
      title={
        isBestOffer
          ? data?.highestOffer
            ? formatAmountForDisplay(
                (data?.highestOffer?.priceInCents ?? 0) / 100,
              )
            : '--'
          : `${data?.totalOffers ?? nifty.stats?.numberOfBids ?? 0}`
      }
    />
  )
}

export default ManageNiftiesOffersCell
