import { getUserNiftyStats } from '@/api/nifty'
import { useUser } from '@/providers/UserContext'
import { useQuery } from '@tanstack/react-query'

export const useUserNiftyStats = () => {
  const { user } = useUser()
  const { data, ...rest } = useQuery({
    queryKey: ['userNiftyStats', user?.id],
    queryFn: async () => {
      const response = await getUserNiftyStats()

      return response.result
    },
    staleTime: 300_000, // 5 minutes
    refetchOnMount: false,
    // only make request if we have a valid user session
    enabled: !!user?.id,
  })

  return {
    userNiftyStats: data,
    ...rest,
  }
}
