import { Box } from '@mui/material'
import { HubbleSearch } from '@/elements/src'

import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useProfileContext } from '@/providers/ProfileContext'

import SortFilter from './SortFilter'
import ChainFilter from './ChainFilter'
import ViewToggle from './ViewToggle'
import WalletFilter from './WalletFilter'

interface ProfileFilterBarProps {
  search: string
  onSearchChange(value: string): void
}

export default function ProfileFilterBar({
  search,
  onSearchChange,
}: ProfileFilterBarProps) {
  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  const {
    state: { profileView },
  } = useProfileContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        justifyContent: 'space-between',
        gap: 1.5,
        mt: 1.5,
        mb: 6,
        width: '100%',
        marginBottom: '48px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <HubbleSearch
          id='searchQuery'
          placeholder='Search'
          value={search}
          onChange={(e) => onSearchChange(e.target.value)}
          onClear={() => onSearchChange('')}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: { xs: 0, sm: 1, md: 1 },
          width: isMobile ? '100%' : undefined,
          overflow: isMobile ? 'auto' : undefined,
        }}
      >
        {profileView !== 'gallery' && (
          <WalletFilter isMobile={isMobile ?? false} />
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: { xs: 0, md: 1 },
            justifyContent: 'flex-end',
          }}
        >
          {profileView !== 'gallery' && (
            <>
              <ChainFilter isMobile={isMobile ?? false} />
              <SortFilter isMobile={isMobile ?? false} />
            </>
          )}
          <ViewToggle isMobile={isMobile ?? false} />
        </Box>
      </Box>
    </Box>
  )
}
