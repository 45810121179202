import { Box, Pagination, styled } from '@mui/material'
import { GridCard, HubbleGrid, HubbleGridChild } from '@/elements/src'

import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useProfileContext } from '@/providers/ProfileContext'

import ProfileNFTItem from './NftItem'
import ManageNiftiesStats from './manage/NiftiesStats'

const StyledContainer = styled(Box)`
  margin-bottom: 30px;
`

interface GridViewProps {
  onItemClick(index?: number): void
}

export default function GridView({ onItemClick }: GridViewProps) {
  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  const {
    data: {
      items,
      totalPages,
      currentPage,
      loading: isLoading,
      onChangePagination,
      onRefetch,
    },
    state: { profileView },
    isOwnProfile,
  } = useProfileContext()

  return (
    <StyledContainer>
      {isOwnProfile && <ManageNiftiesStats />}

      <HubbleGrid style={{ gap: 24, paddingBottom: 24 }}>
        {isLoading &&
          new Array(5).fill(null).map((_, index) => (
            <HubbleGridChild
              key={index}
              style={{ paddingBottom: !isMobile ? 24 : 0 }}
              columns={[
                profileView === 'grid1' ? 4 : 2,
                profileView === 'grid1' ? 8 : 4,
                3,
                3,
              ]}
            >
              <GridCard isLoading title='' href='' mediaSrc='' />
            </HubbleGridChild>
          ))}
        {items?.map((item, index) => (
          <HubbleGridChild
            key={index}
            style={{ paddingBottom: !isMobile ? 24 : 0 }}
            columns={[
              profileView === 'grid1' ? 4 : 2,
              profileView === 'grid1' ? 8 : 4,
              3,
              3,
            ]}
          >
            <ProfileNFTItem
              isMobile={isMobile ?? false}
              isOwnProfile={isOwnProfile}
              item={item}
              onClickItem={onItemClick}
              onRefetch={() => onRefetch()}
            />
          </HubbleGridChild>
        ))}
      </HubbleGrid>
      {totalPages > 1 ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: '24px 0',
          }}
        >
          <Pagination
            onChange={(_, page) => onChangePagination(page)}
            page={currentPage}
            count={totalPages}
            color='primary'
          />
        </div>
      ) : null}
    </StyledContainer>
  )
}
