import { Box, Container, Modal, Typography, styled } from '@mui/material'
import {
  IconArrowDownLine,
  IconArrowUpLine,
  IconChevronLeftLarge,
  IconExpand,
  IconSendOutlined,
} from '@hubble/icons'
import {
  FeeBreakdownModal,
  HubbleButton,
  HubbleSheet,
  Link,
  NiftyCard,
  colors,
  getAssetType,
} from '@/elements/src'

import { useAnalytics } from '@/providers/AnalyticsContext'
import { useCreateTokenWithdrawal } from '@/hooks/useTokenWithdrawal'
import useNiftyDownload from '@/hooks/useNFTDownload'
import useManageNiftiesOffers from '@/hooks/useManageNiftiesOffers'

import TransferNiftyModal from '@/app/(ng-core)/marketplace/item/[contractAddress]/[tokenId]/_components/TransferNiftyModal'
import CancelSaleModal from '@/app/(ng-core)/marketplace/_components/CancelSaleModal'

import { BidOrder, ProfileNiftyV2 } from '@/types/global'
import { INITIAL_FEE_BREAKDOWN } from '../../constants'
import { useState } from 'react'
import ManageNiftyOfferDetail from './NiftyOfferDetail'
import ManageNiftiesModalDetailContainer from './NiftiesModalDetailContainer'
import ManageNiftyDetails from './NiftyDetails'
import ManageNiftyAllOffers from './NiftyAllOffers'

interface ManageNiftiesModalDetailProps {
  nifty: ProfileNiftyV2
  onAcceptBid: (bidOrder: BidOrder, cb?: () => void) => void
  onBack: () => void
  onExpandToFullScreen: (nifty: ProfileNiftyV2) => void
  onRefetch?: () => void
}

const StyledModalContainer = styled(Container)({
  height: '100vh',
  overflowY: 'scroll',
  padding: '24px 20px',
  paddingBottom: 48,
  backgroundColor: colors.niftyWhite,
})

const StyledNFtContainer = styled(Box)({
  gap: 12,
  display: 'flex',
  margin: '24px 0px',
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledAssetContainer = styled(Box)({
  width: 64,
  height: 64,
  borderRadius: 10,
})

const StyledSheetContainer = styled(Box)({
  display: 'flex',
  marginBottom: 24,
  flexDirection: 'column',
  '& .MuiBox-root:last-child': {
    borderBottom: 0,
  },
})

const StyledMoreAction = styled(Box)({
  gap: 1.5,
  display: 'flex',
  padding: '16px 0px',
  flexDirection: 'row',
  borderBottom: `1px solid ${colors.actionGrey}`,
})

function ManageNiftiesModalDetail({
  nifty,
  onAcceptBid,
  onBack,
  onExpandToFullScreen,
  onRefetch,
}: ManageNiftiesModalDetailProps) {
  const { logEvent } = useAnalytics()
  const { createTokenWithdrawal } = useCreateTokenWithdrawal()

  const { downloadNifty } = useNiftyDownload(nifty.imageUrl, nifty.name)

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showSendModal, setShowSendModal] = useState<boolean>(false)
  const [offerSelected, setOfferSelected] = useState<BidOrder | null>(null)
  const [showFeesBreakdown, setShowFeesBreakdown] = useState<boolean>(false)
  const [showCancelListingModal, setShowCancelListingModal] =
    useState<boolean>(false)
  const [feeBreakdown, setFeeBrakdown] = useState<{
    tokenId: string | null
    contractAddress: string | null
    priceInCents: number
  }>(INITIAL_FEE_BREAKDOWN)

  const { data } = useManageNiftiesOffers(nifty)
  const thereIsAnActiveOffer = !!data?.highestOffer

  const handleOnShowFeeBreakdown = () => {
    const priceInCents = offerSelected
      ? offerSelected.priceInCents
      : data?.highestOffer.priceInCents ?? 0

    const modalData = {
      priceInCents,
      tokenId: nifty.tokenId,
      contractAddress: nifty.contractAddress,
    }
    setFeeBrakdown(modalData)
    setShowFeesBreakdown(true)
  }

  return (
    <>
      <Modal open>
        <StyledModalContainer>
          <HubbleButton
            isIconButton
            variant='secondary'
            aria-label='Back to Nifties'
            onClick={() => (offerSelected ? setOfferSelected(null) : onBack())}
          >
            <IconChevronLeftLarge />
          </HubbleButton>

          <StyledNFtContainer>
            <StyledAssetContainer>
              <Link
                openInNewTab
                underline={false}
                href={`/marketplace/item/${nifty.contractAddress}/${nifty.tokenId}`}
              >
                <NiftyCard
                  staticOnly
                  asset={{
                    alt: nifty.name,
                    src: nifty.imageUrl,
                    type: getAssetType(nifty.imageUrl),
                  }}
                />
              </Link>
            </StyledAssetContainer>
            <Box>
              <Typography fontWeight={700} fontSize='24px'>
                {offerSelected ? 'Offer' : 'NFT'} Details
              </Typography>
              <Link
                openInNewTab
                underline={false}
                href={`/marketplace/item/${nifty.contractAddress}/${nifty.tokenId}`}
              >
                <Typography fontWeight={400} fontSize='18px'>
                  For {nifty.name}
                </Typography>
              </Link>
            </Box>
          </StyledNFtContainer>

          {offerSelected ? (
            <Box
              sx={{
                px: 2,
                pb: 2,
              }}
            >
              <ManageNiftyOfferDetail
                offer={offerSelected}
                handleOnClickFeesBreakdown={() => handleOnShowFeeBreakdown()}
                onAcceptBid={(bidOrder) => onAcceptBid?.(bidOrder, onRefetch)}
              />
            </Box>
          ) : (
            <>
              <ManageNiftiesModalDetailContainer label='Details'>
                <ManageNiftyDetails
                  nifty={nifty}
                  handleOnClickMoreOptions={() => setShowMenu(true)}
                  handleOnClickListSale={() => setShowCancelListingModal(true)}
                />
              </ManageNiftiesModalDetailContainer>

              {thereIsAnActiveOffer && (
                <ManageNiftiesModalDetailContainer label='Best Offer'>
                  <ManageNiftyOfferDetail
                    isBestOffer
                    offer={data.highestOffer!}
                    handleOnClickFeesBreakdown={() =>
                      handleOnShowFeeBreakdown()
                    }
                    onAcceptBid={(bidOrder) =>
                      onAcceptBid?.(bidOrder, onRefetch)
                    }
                  />
                </ManageNiftiesModalDetailContainer>
              )}

              <ManageNiftiesModalDetailContainer
                label={`All Offers (${data?.totalOffers ?? 0})`}
              >
                {(data?.totalOffers ?? 0) === 0 ? (
                  <Typography fontSize={14} fontWeight={400}>
                    No active offers for this NFT
                  </Typography>
                ) : (
                  <ManageNiftyAllOffers
                    offers={data?.offers ?? []}
                    handleOnSelectOffer={(offer) => setOfferSelected(offer)}
                  />
                )}
              </ManageNiftiesModalDetailContainer>
            </>
          )}
        </StyledModalContainer>
      </Modal>

      <CancelSaleModal
        niftyTitle={nifty.name}
        tokenId={nifty.tokenId}
        contractAddress={nifty.contractAddress}
        open={showCancelListingModal}
        handleClose={() => setShowCancelListingModal(false)}
        onSuccess={onRefetch}
      />

      <TransferNiftyModal
        name={nifty.name}
        tokenId={nifty.tokenId}
        contractAddress={nifty.contractAddress}
        open={showSendModal}
        handleClose={() => setShowSendModal(false)}
      />

      <FeeBreakdownModal
        isOpen={showFeesBreakdown}
        closeModalFromWithin={() => {
          setShowFeesBreakdown(false)
          setFeeBrakdown(INITIAL_FEE_BREAKDOWN)
        }}
        tokenID={feeBreakdown.tokenId}
        priceInCents={feeBreakdown.priceInCents}
        contractAddress={feeBreakdown.contractAddress}
      />

      <HubbleSheet
        id='more-actions-sheet'
        open={showMenu}
        onOpen={() => setShowMenu(true)}
        onClose={() => setShowMenu(false)}
        onClick={() => setShowMenu(false)}
      >
        <StyledSheetContainer>
          <StyledMoreAction
            onClick={() => {
              logEvent?.('Transfer Click', 'Other')
              setShowSendModal(true)
            }}
          >
            <IconSendOutlined />
            <span>Send</span>
          </StyledMoreAction>
          <StyledMoreAction
            onClick={() =>
              createTokenWithdrawal({
                contract_address: nifty.contractAddress,
                token_id: nifty.tokenId,
              })
            }
          >
            <IconArrowUpLine />
            <span>Withdraw</span>
          </StyledMoreAction>
          <StyledMoreAction onClick={() => downloadNifty()}>
            <IconArrowDownLine />
            <span>Download</span>
          </StyledMoreAction>
          <StyledMoreAction onClick={() => onExpandToFullScreen(nifty)}>
            <IconExpand />
            <span>Expand to Full Screen</span>
          </StyledMoreAction>
        </StyledSheetContainer>
      </HubbleSheet>
    </>
  )
}

export default ManageNiftiesModalDetail
