import { IconArrowsVertical } from '@hubble/icons'
import { HubbleMenu } from '@/elements/src'

import { useAnalytics } from '@/providers/AnalyticsContext'
import { preserveScroll } from '@/utils/filter'

import {
  profileSortOptions,
  useProfileContext,
} from '@/providers/ProfileContext'

interface SortFilterProps {
  isMobile: boolean
}

export default function SortFilter({ isMobile }: SortFilterProps) {
  const { logEvent } = useAnalytics()
  const {
    state: { wallet, ordering, orderingList, profileView },
    updateQuery,
  } = useProfileContext()

  const SORT_OPTIONS = profileSortOptions.map((option) => {
    return (
      <HubbleMenu.Item
        key={option.value}
        value={option.value}
        onClick={() => {
          const eventName = option.label.includes('NFT Name')
            ? 'Profile Page Sort By NFT Name clicked'
            : `Profile Page Sort By ${option.label} clicked`
          logEvent?.(eventName, 'Other')

          preserveScroll(() => {
            if (profileView === 'table') {
              updateQuery({
                orderingList: option.value,
                currentPage: null,
              })
            } else {
              updateQuery({
                ordering: option.value,
                currentPage: null,
              })
            }
          })
        }}
      >
        {option.label}
      </HubbleMenu.Item>
    )
  })

  const selectedSortOption =
    (ordering || orderingList) &&
    profileSortOptions.find(
      (option) => option.value === (ordering || orderingList),
    )

  const getSortDropDownLabel = () => {
    if (isMobile) {
      return <IconArrowsVertical />
    }

    return selectedSortOption
      ? `Sort by ${selectedSortOption.label}`
      : 'Sort by'
  }

  return (
    <HubbleMenu
      id='sort-nifty-types'
      selectedOption={ordering || undefined}
      label={getSortDropDownLabel()}
      buttonProps={{
        isIconButton: isMobile ?? false,
        variant: isMobile ? 'tertiary' : 'secondary',
      }}
    >
      {wallet !== 'custodialWallet' ? SORT_OPTIONS.slice(0, 4) : SORT_OPTIONS}
    </HubbleMenu>
  )
}
