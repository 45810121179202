'use client'

import { Box } from '@mui/material'

import useDebouncedSearch from '@/hooks/useDebouncedSearch'
import { useProfileContext } from '@/providers/ProfileContext'
import useLastProfileTab from '@/hooks/useLastProfileTab'
import { useAnalytics } from '@/providers/AnalyticsContext'

import ScrollToTopRef from '../../marketplace/_components/ScrollToTopRef'
import ProfileFilterBar from './_components/FilterBar'
import SparseMessage from './_components/SparseMessage'
import GridView from './_components/GridView'
import ManageView from './_components/ManageView'
import GalleryView from './_components/GalleryView'

export default function ProfileNftsClientShell() {
  const {
    scrollToRef,
    state: { profileView },
    data: { loading, totalResults },
    isOwnProfile,
    updateQuery,
  } = useProfileContext()
  const { logEvent } = useAnalytics()

  const { querySearch, setQuerySearch } = useDebouncedSearch()
  const [lastProfileTab] = useLastProfileTab()

  const handleOnClickItem = () =>
    logEvent?.('Profile Page NFTs item clicked ', 'Navigation')
  const renderProfileView = () => {
    if (!profileView || ['grid', 'grid1', 'grid2'].includes(profileView))
      return <GridView onItemClick={handleOnClickItem} />

    if (profileView === 'gallery')
      return <GalleryView onItemClick={handleOnClickItem} />

    if (profileView === 'table' && isOwnProfile) return <ManageView />

    if (!['grid1', 'grid2'].includes(profileView)) {
      updateQuery({
        profileView: isOwnProfile ? lastProfileTab : 'grid',
      })
    }

    return null
  }

  return (
    <div>
      <ScrollToTopRef ref={scrollToRef} />
      <ProfileFilterBar search={querySearch} onSearchChange={setQuerySearch} />
      <Box
        sx={{
          minHeight: '100vh',
        }}
      >
        {renderProfileView()}
        {!loading && (
          <SparseMessage
            isOwnProfile={isOwnProfile}
            totalResults={totalResults}
          />
        )}
      </Box>
    </div>
  )
}
