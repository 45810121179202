import { useInfiniteQuery } from '@tanstack/react-query'
import { getNiftiesByUrl } from '@/api/profile'
import { isDevelopmentEnv } from '@/elements/src'

export type NiftiesParamsType = {
  page?: number
  page_size?: number
}

const useManageNifties = (
  search: string,
  profileUrl: string,
  params: NiftiesParamsType = { page_size: 20 },
) => {
  const searchParams = new URLSearchParams(location.search)
  const ordering = searchParams.get('orderingList') ?? ''
  const chain = searchParams.get('chainList') ?? ''

  const query = useInfiniteQuery(
    ['profileManageNifties', profileUrl, search, params, ordering, chain],
    async ({ pageParam = params.page ?? 1 }) => {
      const pageNumber = pageParam

      const response = await getNiftiesByUrl(profileUrl, {
        params: {
          ...params,
          search,
          page: pageNumber,
          ordering,
          chain: chain === 'ethereum' && isDevelopmentEnv() ? 'sepolia' : chain,
        },
      })

      const { results: nifties, count: totalResults } = response
      const totalPages = Math.ceil(totalResults / params.page_size!)
      const thereIsNextPage = totalPages > pageNumber

      return {
        thereIsNextPage,
        totalPages,
        items: nifties,
        totalItems: totalResults,
        nextPage: thereIsNextPage ? pageNumber + 1 : false,
      }
    },
    {
      enabled: !!profileUrl,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage ?? false
      },
      getPreviousPageParam: () => {
        return false
      },
    },
  )

  const totalPages = query.data?.pages[0].totalPages ?? 0
  const totalItems = query.data?.pages[0].totalItems ?? 0
  const items = query.data?.pages.flatMap((page) => page.items)

  return { query, items, totalPages, totalItems }
}

export default useManageNifties
