import {
  NiftyMetaDataMintedConfig,
  NiftyMetaDataMintedResponse,
  ApiResponse,
} from "@/types/api_types";
import { Nullable, MintedNiftyMetadata } from "@/types/global";
import { createURL, getErrorPayload, getRequestNotOkMsg } from "@/utils/api";
import { fetchWithHeader, createQueryString } from "@/utils/api";
import getQueryClient from "@/utils/get-query-client";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

const queryClient = getQueryClient();

export const getAMNOQueryKey = (
  contractAddress: Nullable<string | number>,
  tokenId: Nullable<string | number>
) => ["currentNiftyEdition", contractAddress, tokenId];

export async function getNiftyMetaDataMinted(
  params: NiftyMetaDataMintedConfig
): Promise<ApiResponse<NiftyMetaDataMintedResponse>> {
  try {
    const response = await fetchWithHeader(
      createURL(`/nifty/metadata-minted/?${createQueryString(params)}`)
    );

    if (!response.ok) {
      throw getRequestNotOkMsg(response.status);
    }

    const results = await response.json();

    return { data: results };
  } catch (err) {
    throw getErrorPayload(err);
  }
}

type WithAddressAndToken = {
  contractAddress: Nullable<string>;
  tokenId: Nullable<string>;
};
/** Use to force webapp to refetch the minted nifty metadata via (react-query) cache busting  */
export const checkForUpdatedNifty = <T extends WithAddressAndToken>({
  contractAddress,
  tokenId,
}: T) => {
  queryClient.invalidateQueries(getAMNOQueryKey(contractAddress, tokenId));
};

export function getCurrentNiftyEditionQueryConfig(
  options: NiftyMetaDataMintedConfig
) {
  return {
    queryKey: ["currentNiftyEdition", options.contractAddress, options.tokenId],
    queryFn: async () => {
      const { data } = await getNiftyMetaDataMinted(options);

      return data!.niftyMetadata;
    },
  };
}

function useCurrentNiftyEdition(
  { contractAddress, tokenId }: NiftyMetaDataMintedConfig,
  options?: Omit<
    UseQueryOptions<
      unknown,
      unknown,
      MintedNiftyMetadata,
      (string | null | undefined | number)[]
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...getCurrentNiftyEditionQueryConfig({ contractAddress, tokenId }),
    refetchOnWindowFocus: false,
    staleTime: 600_000, // 10 mins
    refetchInterval: 600_000,
    ...options,
  });
}

export default useCurrentNiftyEdition;
