import { useCallback, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'

import { GridCard } from '@/elements/src'

import { ProfileData } from '@/types/profile'

import ProfileNFTItem from '../NftItem'

const CarouselItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActiveItem',
})<{ isActiveItem: boolean }>(({ theme, isActiveItem }) => ({
  borderRadius: 0,
  '.cover--wrapper': {
    height: '600px',
    paddingTop: 0,
    '.cover--content': {
      height: '100%',
      position: 'relative',
      alignItems: 'center',
      img: {
        borderRadius: 0,
        objectFit: 'contain',
        position: 'unset',
        width: 'unset',
      },
      '.loader--skeleton': {
        borderRadius: 0,
        width: '600px',
        position: 'relative',
        '.loader--asset': {
          position: 'absolute',
          width: '100%',
        },
        '& + img': {
          position: 'absolute',
          width: '100%',
        },
      },
      ':has(.loader--skeleton)': {
        '.top-right--container': {
          opacity: '0 !important',
          pointerEvents: 'none !important',
        },
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '.cover--wrapper': {
      height: 'auto',
      '.cover--content': {
        img: {
          height: 'auto',
          maxHeight: '400px',
          maxWidth: '300px',
        },
        '.loader--skeleton': {
          width: '300px',
          img: {
            height: 'auto',
            maxHeight: '400px',
            maxWidth: '300px',
          },
        },
      },
    },
    '.content': {
      opacity: isActiveItem ? '1' : '0',
    },
    a: {
      pointerEvents: isActiveItem ? 'initial' : 'none',
    },
  },
}))

type GalleryCarouselItemProps =
  | {
      isLoading?: false
      isMobile: boolean
      isOwnProfile: boolean
      item: ProfileData['items'][0]
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
      onRefetch: () => void
      scrollContainerRef: React.RefObject<HTMLDivElement>
    }
  | {
      isLoading: true
      isMobile: boolean
      isOwnProfile: boolean
      scrollContainerRef: React.RefObject<HTMLDivElement>
    }

export default function GalleryCarouselItem(props: GalleryCarouselItemProps) {
  const componentRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  const checkVisible = useCallback(() => {
    if (!componentRef.current) return false

    const boundingClientRect = componentRef.current.getBoundingClientRect()
    const viewWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth,
    )

    const { left, right, width } = boundingClientRect
    const computedWidth = width + 30

    return (
      (left + computedWidth / 3 >= 0 && left + computedWidth <= viewWidth) ||
      (right <= viewWidth && right - computedWidth >= 0)
    )
  }, [])

  useEffect(() => {
    setIsVisible(checkVisible())

    const scrollRef = props.scrollContainerRef.current
    scrollRef?.addEventListener('scroll', () => {
      setIsVisible(checkVisible())
    })
    return () => {
      scrollRef?.removeEventListener('scroll', () => {
        setIsVisible(checkVisible())
      })
    }
  }, [checkVisible, props.scrollContainerRef])

  if (props.isLoading) {
    return (
      <CarouselItem ref={componentRef} isActiveItem={isVisible}>
        <GridCard isLoading href='' mediaSrc='' title='' />
      </CarouselItem>
    )
  }

  return (
    <CarouselItem
      ref={componentRef}
      onClick={props.onClick}
      isActiveItem={isVisible}
    >
      <ProfileNFTItem
        isMobile={props.isMobile}
        isOwnProfile={props.isOwnProfile}
        item={props.item}
        onRefetch={() => props.onRefetch()}
      />
    </CarouselItem>
  )
}
