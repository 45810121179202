import { useState } from 'react'

import {
  IconCheck,
  IconCheckboxUnselected,
  IconViewArt,
  IconViewGrid,
  IconViewGridLarge,
  IconViewList,
} from '@hubble/icons'
import { Button, Segment } from '@hubble/web'
import { HubbleSheet, Tooltip } from '@/elements/src'
import { Box } from '@mui/material'
import { web } from '@hubble/tokens'

import { Order } from '@/types/profile'

import useLastProfileTab from '@/hooks/useLastProfileTab'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { useProfileContext } from '@/providers/ProfileContext'

interface ViewToggleProps {
  isMobile: boolean
}

export default function ViewToggle({ isMobile }: ViewToggleProps) {
  const [, setLastProfileTab] = useLastProfileTab()
  const { logEvent } = useAnalytics()

  const [showViewSheet, setShowViewSheet] = useState<boolean>(false)

  const {
    state: { chain, chainList, ordering, orderingList, profileView },
    updateQuery,
    isOwnProfile,
  } = useProfileContext()

  const getViewDropDownIcon = () => {
    switch (profileView) {
      case 'grid2':
        return <IconViewGridLarge height={20} width={20} />
      case 'grid1':
        return <IconCheckboxUnselected height={20} width={20} />
      case 'table':
        return <IconViewList height={20} width={20} />

      default:
        return <IconViewArt height={20} width={20} />
    }
  }

  const getViewButtonGroupOptionsMobile = () => {
    const buttonGroupOptions = [
      {
        value: 'grid2',
        label: '2 cards per row',
        icon: <IconViewGridLarge height={24} width={24} />,
        onClick: () => logEvent?.('Profile Page Gallery View clicked', 'Other'),
      },
      {
        value: 'grid1',
        label: '1 card per row',
        icon: <IconCheckboxUnselected height={24} width={24} />,
        onClick: () => logEvent?.('Profile Page Gallery View clicked', 'Other'),
      },
      {
        value: 'table',
        label: 'List',
        icon: <IconViewList height={24} width={24} />,
        onClick: () => logEvent?.('Profile Page Manage View clicked', 'Other'),
      },
      {
        value: 'gallery',
        label: 'Gallery',
        icon: <IconViewArt height={24} width={24} />,
        onClick: () => logEvent?.('Profile Page Art View clicked', 'Other'),
      },
    ]

    if (!isOwnProfile) {
      buttonGroupOptions.splice(2, 1)
    }

    return buttonGroupOptions
  }

  const getViewButtonGroupOptions = () => {
    const buttonGroupOptions = [
      {
        value: 'grid',
        label: 'Cards View',
        ariaLabel: 'Select Grid View',
        icon: <IconViewGrid height={20} width={20} />,
        onClick: () => logEvent?.('Profile Page Gallery View clicked', 'Other'),
      },
      {
        value: 'table',
        label: 'List View',
        ariaLabel: 'Select Manage View',
        icon: <IconViewList height={20} width={20} />,
        onClick: () => logEvent?.('Profile Page Manage View clicked', 'Other'),
      },
      {
        value: 'gallery',
        label: 'Gallery View',
        ariaLabel: 'Select Gallery View',
        icon: <IconViewArt height={20} width={20} />,
        onClick: () => logEvent?.('Profile Page Art View clicked', 'Other'),
      },
    ]

    return isOwnProfile
      ? buttonGroupOptions
      : buttonGroupOptions.filter((option) => option.value !== 'table')
  }

  const buttonGroupOptions = getViewButtonGroupOptions()

  return (
    <>
      {isMobile ? (
        <Button.Tertiary
          icon={getViewDropDownIcon()}
          aria-label='mobile-view-dropdown'
          onClick={() => setShowViewSheet(true)}
        />
      ) : (
        <Segment
          mt={0}
          hasBackground
          variant='secondary'
          value={profileView}
          onChange={(item) => {
            const isGridView = ['grid', 'grid1', 'grid2'].includes(item)

            updateQuery({
              chain: item !== 'table' ? chain : undefined,
              chainList: !isGridView ? chainList : undefined,
              ordering: item !== 'table' ? (ordering as Order) : undefined,
              orderingList: !isGridView ? (orderingList as Order) : undefined,
              profileView: item,
              wallet: item === 'table' ? 'custodialWallet' : undefined,
            })
            if (isOwnProfile) {
              setLastProfileTab(item)
            }
          }}
        >
          {buttonGroupOptions.map((button) => (
            <Segment.Button
              aria-label={button.ariaLabel}
              icon={
                <Tooltip placement='top' body={button.label}>
                  <Box>{button.icon}</Box>
                </Tooltip>
              }
              key={button.value}
              onClick={button.onClick}
              style={{ padding: '0px 10px' }}
              value={button.value}
            />
          ))}
        </Segment>
      )}

      <HubbleSheet
        id='view-sheet'
        open={showViewSheet}
        onOpen={() => setShowViewSheet(true)}
        onClose={() => setShowViewSheet(false)}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
          {getViewButtonGroupOptionsMobile().map((button) => (
            <Box
              sx={{
                py: 1,
                px: 0.5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
              }}
              key={button.value}
              onClick={() => {
                button.onClick()

                updateQuery({
                  profileView: button.value,
                  wallet:
                    button.value === 'table' ? 'custodialWallet' : undefined,
                })

                setShowViewSheet(false)
              }}
            >
              <Button
                leftElement={button.icon}
                style={{ padding: 0, fontSize: 16, fontWeight: 400 }}
              >
                {button.label}
              </Button>
              {profileView === button.value && <IconCheck />}
            </Box>
          ))}
        </Box>
      </HubbleSheet>
    </>
  )
}
