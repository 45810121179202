import React from 'react'

interface ImmutablexLogoProps {
  width?: number
  height?: number
}

function ImmutablexLogo({ width = 20, height = 20 }: ImmutablexLogoProps) {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='12' fill='#131313' />
      <path
        fill='white'
        d='M6.93218 17.2884H5.51562L9.85916 12.2578C9.99796 12.0971 9.99796 11.8562 9.85916 11.6998L5.54828 6.71143H6.91176L11.2471 11.742C11.3573 11.89 11.3778 12.1056 11.2594 12.2451C10.1245 13.5598 6.93218 17.2884 6.93218 17.2884Z'
      />
      <path
        fill='white'
        d='M18.4523 6.71143H17.0929L11.6064 13.1032C11.4635 13.2597 11.4594 13.5091 11.5982 13.6697L11.7452 13.8557L14.6885 17.2884H16.1091L12.9249 13.5852C12.7861 13.4245 12.7902 13.1751 12.9331 13.0187L18.4523 6.71143Z'
      />
      <path
        fill='white'
        d='M13.9373 13.2089C13.9047 13.34 13.9373 13.4837 14.0271 13.5894C14.8191 14.5152 17.195 17.2884 17.195 17.2884H18.485L14.4313 12.596L14.0639 13.0018C14.0149 13.0567 13.9618 13.1371 13.9373 13.2089Z'
      />
      <path
        fill='white'
        d='M7.99763 6.71143H9.30804C9.30804 6.71143 11.6431 9.50574 12.4881 10.3724C12.6228 10.5076 12.5983 10.6852 12.4759 10.8289C12.0268 11.3573 12.0146 11.3658 12.0146 11.3658L7.99763 6.71143Z'
      />
      <path
        fill='white'
        d='M13.2189 9.95806L16.007 6.71143H14.6885L12.8106 8.90121C12.6678 9.05763 12.6637 9.30282 12.8025 9.46769C12.9086 9.59028 13.2189 9.95806 13.2189 9.95806Z'
      />
      <path
        fill='white'
        d='M8.00993 17.2884L10.7981 14.0418C10.7981 14.0418 11.1084 14.4096 11.2145 14.5322C11.3533 14.697 11.3492 14.9422 11.2063 15.0986L9.3285 17.2884H8.00993Z'
      />
    </svg>
  )
}

export default ImmutablexLogo
