import { useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'

import {
  NiftyAssetProps,
  ProfileFullScreenModal,
  getAssetType,
} from '@/elements/src'

import useDebouncedSearch from '@/hooks/useDebouncedSearch'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useProfileContext } from '@/providers/ProfileContext'

import { BidOrder, ProfileNiftyV2 } from '@/types/global'

import ProfileAccepBidModal from './ProfileAcceptBidModal'
import ManageNiftiesModalDetail from './manage/NiftiesModalDetail'
import ManageNiftyOffers from './manage/NiftyOffers'
import ManageNiftiesStats from './manage/NiftiesStats'
import ManageNiftiesTable from './manage/NiftiesTable'
import ManageExternalNiftiesTable from './manage/ExternalNiftiesTable'

interface ManageViewProps {
  onItemClick?(): void
}

interface ExpandedNiftyProps {
  asset: NiftyAssetProps
  name: string
}

export default function ManageView({ onItemClick }: ManageViewProps) {
  const { debouncedQuerySearch } = useDebouncedSearch()
  const {
    state: { selectedNifty, wallet },
    profile,
    setState,
    scrollToTop,
  } = useProfileContext()

  const [fullScreenOpen, setFullScreenOpen] = useState<boolean>(false)
  const [isMobileRefetch, setIsMobileRefetch] = useState<boolean>(false)
  const [expandedNifty, setExpandedNifty] = useState<ExpandedNiftyProps | null>(
    null,
  )

  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  const [offerState, setOfferState] = useState<{
    finishCallback: () => void
    globalBidOrder: BidOrder | null
    showModal: boolean
  }>({
    finishCallback: () => {},
    globalBidOrder: null,
    showModal: false,
  })

  const handleSelectNifty = (newSelectedNifty: ProfileNiftyV2) => {
    scrollToTop(() => {
      setState((prevState) => ({
        ...prevState,
        selectedNifty: newSelectedNifty,
      }))
    })
  }

  const handleBack = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedNifty: null,
    }))
  }, [setState])

  const handleAcceptOfferConfirm = useCallback(
    (bidOrder: BidOrder, cb?: () => void) => {
      setOfferState({
        finishCallback: () => {
          cb?.()
          handleBack()
        },
        globalBidOrder: bidOrder,
        showModal: true,
      })
    },
    [setOfferState, handleBack],
  )

  const handleExpendToFullScreen = useCallback((nifty: ProfileNiftyV2) => {
    setExpandedNifty({
      asset: {
        imageUrl: nifty.imageUrl || '',
        src: nifty.imageUrl || '',
        staticOnly: true,
        type: getAssetType(nifty.imageUrl || ''),
      },
      name: nifty.name,
    })
    setTimeout(() => setFullScreenOpen(true), 250)
  }, [])

  useEffectOnce(() => {
    return () => handleBack()
  })

  return (
    <>
      <div>
        {selectedNifty ? (
          isMobile ? (
            <ManageNiftiesModalDetail
              nifty={selectedNifty}
              onAcceptBid={handleAcceptOfferConfirm}
              onBack={handleBack}
              onExpandToFullScreen={handleExpendToFullScreen}
              onRefetch={() => {
                setIsMobileRefetch(true)
                handleBack()
              }}
            />
          ) : (
            <ManageNiftyOffers
              nifty={selectedNifty}
              onBack={handleBack}
              onAcceptBid={handleAcceptOfferConfirm}
            />
          )
        ) : wallet === 'custodialWallet' ? (
          <>
            <ManageNiftiesStats />
            <ManageNiftiesTable
              isMobile={isMobile ?? false}
              isMobileRefetch={isMobileRefetch}
              onAcceptBid={handleAcceptOfferConfirm}
              onItemClick={onItemClick}
              onExpandToFullScreen={handleExpendToFullScreen}
              onMobileRefecthCallback={() => setIsMobileRefetch(false)}
              onSelect={handleSelectNifty}
              profileUrl={profile?.profileUrl as string}
              search={debouncedQuerySearch}
            />
          </>
        ) : (
          <ManageExternalNiftiesTable
            isMobile={isMobile ?? false}
            onItemClick={onItemClick}
          />
        )}
      </div>

      <ProfileAccepBidModal
        bidOrder={offerState.globalBidOrder!}
        open={offerState.showModal}
        onRefetch={offerState.finishCallback}
        onClose={() =>
          setOfferState({
            finishCallback: () => {},
            globalBidOrder: null,
            showModal: false,
          })
        }
      />

      {expandedNifty && (
        <ProfileFullScreenModal
          asset={expandedNifty?.asset}
          isOpen={fullScreenOpen}
          isOwnProfile
          onClose={() => {
            setExpandedNifty(() => {
              setFullScreenOpen(false)

              return null
            })
          }}
          title={expandedNifty?.name}
        />
      )}
    </>
  )
}
