import { useState } from 'react'

import { Box } from '@mui/material'
import { HubbleSheet, Tooltip } from '@/elements/src'
import { Button, Segment } from '@hubble/web'
import { IconCheck } from '@hubble/icons'
import { web } from '@hubble/tokens'
import { BaseChain } from '@/types/collection'

import { useProfileContext } from '@/providers/ProfileContext'

import ImmutablexLogo from '../logos/ImmutablexLogo'
import PolygonLogo from '../logos/PolygonLogo'
import EthereumLogo from '../logos/EthereumLogo'

interface ChainFilterProps {
  isMobile: boolean
}

export default function ChainFilter({ isMobile }: ChainFilterProps) {
  const [showChainSheet, setShowChainSheet] = useState<boolean>(false)

  const {
    state: { chain, chainList, profileView },
    updateQuery,
    scrollToTop,
  } = useProfileContext()

  const handleUpdateFilters = (newChain?: BaseChain) => {
    scrollToTop(() => {
      if (profileView === 'table') {
        updateQuery({
          chainList: newChain,
        })
      } else {
        updateQuery({
          chain: newChain,
        })
      }
    })
  }

  const getChainDropDownIcon = () => {
    switch (chain || chainList) {
      case BaseChain.immutablex:
        return <ImmutablexLogo />
      case BaseChain.polygon:
        return <PolygonLogo />

      default:
        return <EthereumLogo />
    }
  }

  const getChainButtonGroupOptions = () => {
    return [
      {
        value: 'ethereum',
        label: 'Ethereum',
        ariaLabel: 'Filter by Ethereum Chain',
        icon: (
          <EthereumLogo
            width={isMobile ? 24 : 20}
            height={isMobile ? 24 : 20}
          />
        ),
      },
      {
        value: 'immutablex',
        label: 'Immutable X',
        ariaLabel: 'Filter by ImmutableX Chain',
        icon: (
          <ImmutablexLogo
            width={isMobile ? 24 : 20}
            height={isMobile ? 24 : 20}
          />
        ),
      },
      {
        value: 'polygon',
        label: 'Polygon',
        ariaLabel: 'Filter by Polygon Chain',
        icon: (
          <PolygonLogo width={isMobile ? 24 : 20} height={isMobile ? 24 : 20} />
        ),
      },
    ]
  }

  return (
    <>
      {isMobile ? (
        <Button.Tertiary
          icon={getChainDropDownIcon()}
          aria-label='mobile-chain-dropdown'
          onClick={() => setShowChainSheet(true)}
        />
      ) : (
        <Segment
          mt={0}
          hasBackground
          value={chain || chainList}
          variant='secondary'
          onChange={(item) => {
            handleUpdateFilters(
              item === (chain || chainList) ? undefined : (item as BaseChain),
            )
          }}
        >
          {getChainButtonGroupOptions().map((button) => (
            <Segment.Button
              aria-label={button.ariaLabel}
              icon={
                <Tooltip placement='top' body={button.label}>
                  <Box>{button.icon}</Box>
                </Tooltip>
              }
              key={button.value}
              style={{ padding: '0px 10px' }}
              value={button.value}
            />
          ))}
        </Segment>
      )}

      <HubbleSheet
        id='chain-sheet'
        open={showChainSheet}
        onOpen={() => setShowChainSheet(true)}
        onClose={() => setShowChainSheet(false)}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
          {getChainButtonGroupOptions().map((button) => (
            <Box
              sx={{
                py: 1,
                px: 0.5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
              }}
              key={button.value}
              onClick={() => {
                handleUpdateFilters(
                  button.value === (chain || chainList)
                    ? undefined
                    : (button.value as BaseChain),
                )

                setShowChainSheet(false)
              }}
            >
              <Button
                leftElement={button.icon}
                style={{ padding: 0, fontSize: 16, fontWeight: 400 }}
              >
                {button.label}
              </Button>
              {(chain || chainList) === button.value && <IconCheck />}
            </Box>
          ))}
        </Box>
      </HubbleSheet>
    </>
  )
}
