import { useMemo } from 'react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useUser } from '@/providers/UserContext'

import { getUserVerifiedWallets } from '@/api/wallet'

import { Wallet } from '@/types/global'
import useAuth from './useAuth'

export const getVerifiedWalletsQueryKey = (userId?: string | number) => [
  'user',
  userId,
  'verifiedWallets',
]

const useUserVerifiedWallets = (
  options?: Omit<
    UseQueryOptions<
      unknown,
      unknown,
      Wallet[],
      (string | null | undefined | number)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { user } = useUser()
  const { isLoggedIn } = useAuth()
  const queryKey = useMemo(() => getVerifiedWalletsQueryKey(user.id), [user.id])

  return useQuery({
    queryKey,
    queryFn: () => getUserVerifiedWallets().then((res) => res.verifiedWallets),
    enabled: !!user && isLoggedIn(),
    // We force a refetch on focus so users returning from setting up
    // their wallets don't need to refresh the page
    refetchOnWindowFocus: 'always',
    staleTime: 30_000,
    ...options,
  } as UseQueryOptions<unknown, unknown, Wallet[], (string | null | undefined | number)[]>)
}

export default useUserVerifiedWallets
