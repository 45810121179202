import React from 'react'
import { formatDistanceToNowStrict } from 'date-fns'

import { Box, List, styled } from '@mui/material'
import { HubbleButton } from '@/elements/src'

import formatAmountForDisplay from '@/utils/format-amount-for-display'
import { BidOrder } from '@/types/global'

import ManageNiftiesModalDetailRow from './NiftiesModalDetailRow'

const StyledButtonsContainer = styled(Box)({
  gap: 12,
  display: 'flex',
  marginTop: '16px',
  flexDirection: 'row',
})
interface NiftyAllOffersProps {
  offers: BidOrder[]
  handleOnSelectOffer: (bidOrder: BidOrder) => void
}

function ManageNiftyAllOffers({
  offers,
  handleOnSelectOffer,
}: NiftyAllOffersProps) {
  const [showMore, setShowMore] = React.useState<boolean>(false)
  const [showMoreButton, setShowMoreButton] = React.useState<boolean>(false)

  React.useEffect(() => {
    const localShowMoreButton = offers.length > 5

    if (localShowMoreButton === showMoreButton) return

    if (offers.length > 5) setShowMoreButton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers])

  return (
    <>
      <List sx={{ padding: 0 }}>
        {(showMoreButton && !showMore ? offers.slice(0, 5) : offers).map(
          (offer) => (
            <Box key={offer.id} onClick={() => handleOnSelectOffer(offer)}>
              <ManageNiftiesModalDetailRow
                label={formatDistanceToNowStrict(new Date(offer.Timestamp), {
                  addSuffix: true,
                })}
                value={formatAmountForDisplay((offer.priceInCents ?? 0) / 100)}
              />
            </Box>
          ),
        )}
      </List>

      {showMoreButton && (
        <StyledButtonsContainer>
          <HubbleButton
            variant='secondary'
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show Less' : 'Show More'}
          </HubbleButton>
        </StyledButtonsContainer>
      )}
    </>
  )
}

export default ManageNiftyAllOffers
