import React from 'react'

interface EthereumLogoProps {
  width?: number
  height?: number
}

function EthereumLogo({ width = 20, height = 20 }: EthereumLogoProps) {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='12' fill='black' />
      <path
        fill='white'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0006 5.3999L16.29 12.0355L12.0006 14.3999L7.70997 12.036L12.0006 5.3999ZM11.9988 15.1152L16.29 12.5999L11.9988 18.5999L7.70996 12.6005L11.9988 15.1152Z'
      />
    </svg>
  )
}

export default EthereumLogo
