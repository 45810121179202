import { useState } from 'react'

import { Box, styled } from '@mui/material'
import { ChevronLeft } from 'react-feather'
import { Button, FeeBreakdownModal, Typography } from '@/elements/src'

import useManageNiftiesOffers from '@/hooks/useManageNiftiesOffers'

import { BidOrder, ProfileNiftyV2 } from '@/types/global'

import ManageNiftyOffersNFT from './NiftyOffersNFT'
import ManageNiftyOffersTable from './NiftyOffersTable'

const StyledHeaderContainer = styled(Box)({
  gap: '8px',
  display: 'flex',
  marginBottom: 24,
  alignItems: 'center',
  flexDirection: 'row',
})

const StyledBackButton = styled(Button)({
  padding: 12,
  border: 'none',
  background: '#F6F6F6',
})

interface ManageNiftyOfferProps {
  nifty: ProfileNiftyV2
  onBack: () => void
  onAcceptBid: (bidOrder: BidOrder, cb: () => void) => void
}

export default function ManageNiftyOffers({
  nifty,
  onBack,
  onAcceptBid,
}: ManageNiftyOfferProps) {
  // Fee breakdown state
  const [feeBreakdownState, setFeeBreakdownState] = useState<{
    isModalOpen: boolean
    contractAddress: string | null
    tokenId: string | null
    priceInCents: number
  }>({
    isModalOpen: false,
    contractAddress: null,
    tokenId: null,
    priceInCents: 0,
  })

  const { data } = useManageNiftiesOffers(nifty)

  const handleClickFeesBreakdown = (bidOrder: BidOrder) => {
    setFeeBreakdownState((prevState) => ({
      ...prevState,
      isModalOpen: true,
      contractAddress: nifty?.contractAddress,
      tokenId: nifty?.tokenId,
      priceInCents: bidOrder.priceInCents,
    }))
  }

  return (
    <>
      <StyledHeaderContainer>
        <StyledBackButton
          color='tertiary'
          aria-label='Back to Nifties'
          onClick={onBack}
        >
          <ChevronLeft />
        </StyledBackButton>
        <strong>Back</strong>
      </StyledHeaderContainer>

      <ManageNiftyOffersNFT nifty={nifty} />

      <Typography variant='heading' sx={{ fontWeight: 600, mb: 3 }}>
        Offers ({data?.totalOffers ?? 0})
      </Typography>

      <ManageNiftyOffersTable
        items={data?.offers ?? []}
        onAcceptBid={onAcceptBid}
        onClickFeesBreakdown={handleClickFeesBreakdown}
      />

      <FeeBreakdownModal
        closeModalFromWithin={() =>
          setFeeBreakdownState({
            isModalOpen: false,
            priceInCents: 0,
            tokenId: null,
            contractAddress: null,
          })
        }
        priceInCents={feeBreakdownState.priceInCents}
        isOpen={feeBreakdownState.isModalOpen}
        contractAddress={feeBreakdownState.contractAddress}
        tokenID={feeBreakdownState.tokenId}
      />
    </>
  )
}
