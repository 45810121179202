import { ListItem, Typography, styled } from '@mui/material'
import { colors } from '@/elements/src'

const StyledListItem = styled(ListItem)({
  padding: 0,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${colors.actionGrey}`,
  '& > p': {
    width: '50%',
    padding: '16px 0px',
  },
})

const StyledTruncatedText = styled(Typography)({
  lineClamp: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxOrient: 'vertical',
  textOverflow: 'ellipsis',
})

interface ManageNiftiesModalDetailRowProps {
  label: string | React.ReactNode
  value: string | React.ReactNode
}

function ManageNiftiesModalDetailRow({
  label,
  value,
}: ManageNiftiesModalDetailRowProps) {
  return (
    <StyledListItem>
      {typeof label === 'string' ? (
        <Typography fontSize={16} fontWeight={400}>
          {label}
        </Typography>
      ) : (
        label
      )}
      {typeof value === 'string' ? (
        <StyledTruncatedText fontSize={16} fontWeight={400} textAlign='right'>
          {value}
        </StyledTruncatedText>
      ) : (
        value
      )}
    </StyledListItem>
  )
}

export default ManageNiftiesModalDetailRow
