import { HubbleTableColumnProps } from '@/elements/src'
import {
  ManageExternalNiftiesTableColumn,
  ManageNiftiesTableColumn,
} from '@/types/profile'

export const INITIAL_FEE_BREAKDOWN = {
  tokenId: null,
  contractAddress: null,
  priceInCents: 0,
}

export const NIFTY_OFFERS_COLUMNS: HubbleTableColumnProps[] = [
  { id: 'offer_from', label: 'Offer From', width: '180px' },
  { id: 'offer', label: 'Offer', align: 'right', width: '150px' },
  {
    id: 'total_after_fees',
    label: 'Total After Fees',
    align: 'right',
    width: '150px',
  },
  { id: 'bid_type', label: 'Bid Type', width: '100px' },
  { id: 'payment', label: 'Payment', width: '100px' },
  { id: 'offer_date', label: 'Offer Date', width: '150px' },
  { id: 'expiration', label: 'Expiration', width: '120px' },
  { id: 'button', label: '', width: '130px' },
]

export const MANAGE_NIFTIES_COLUMNS: ManageNiftiesTableColumn[] = [
  { id: 'nft_creator', label: 'NFT and Creator', width: '215px' },
  {
    id: 'purchase_price',
    label: 'Purchase Price',
    width: '120px',
    hideOn: 900,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'floor_price',
    label: 'Floor Price',
    width: '90px',
    hideOn: 990,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'last_sale',
    label: 'Last Sale',
    width: '90px',
    hideOn: 1070,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'avg_resale',
    label: 'Avg. Resale',
    width: '95px',
    hideOn: 1150,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'listing_price',
    label: 'Listing Price',
    width: '105px',
    hideOn: 1350,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'offers',
    label: 'Offers',
    width: '65px',
    hideOn: 1420,
    hideOnMobile: true,
    align: 'right',
  },
  {
    id: 'best_active_offer',
    label: 'Best Active Offer',
    width: '135px',
    hideOnMobile: true,
    align: 'right',
  },
  { id: 'offers_button', label: '', width: '110px', hideOnMobile: true },
  { id: 'list_button', label: '', width: '100px', hideOnMobile: true },
  { id: 'more_button', label: '', width: '50px', hideOnMobile: true },
  { id: 'offers_badge', label: '', width: '75px', hideOnDesktop: true },
]

export const MANAGE_EXTERNAL_NIFTIES_COLUMNS: ManageExternalNiftiesTableColumn[] =
  [
    { id: 'nft_creator', label: 'NFT and Creator', width: '25%' },
    {
      id: 'collection',
      label: 'Collection',
      width: '25%',
      hideOnMobile: true,
    },
    {
      id: 'contract',
      label: 'Contract',
      width: '25%',
      hideOnSingle: false,
    },
    {
      id: 'wallet',
      label: 'Wallet',
      width: '25%',
      hideOnSingle: true,
    },
  ]

export const MANAGE_NIFTIES_PAGE_SIZE = 20
export const NIFTY_ITEM_OPTIONS_TEXT = {
  accept: 'Accept Best Offer',
  view: 'View Offers',
  withdraw: 'Withdraw',
  download: 'Download',
  send: 'Send',
  expand: 'Expand to Full Screen',
  list: 'List',
  unlist: 'Unlist',
}

export const WEEK_IN_MILISECONDS = 604800000
