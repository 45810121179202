import { URL } from '@/app/constants'
import { DoggoEmptyState } from '@/elements/src'
import { Button } from '@hubble/web'
import { Box, Typography, styled } from '@mui/material'

const StyledContainer = styled(Box)`
  text-align: center;
  margin-top: 64px;
`

const StyledBody = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
`

interface SparseMessageProps {
  isOwnProfile: boolean
  totalResults: number
}

export default function SparseMessage({
  isOwnProfile,
  totalResults,
}: SparseMessageProps) {
  if (totalResults < 3 && isOwnProfile) {
    return (
      <StyledContainer>
        <StyledBody>Your profile is looking a little sparse.</StyledBody>
        <Button.Secondary size='lg' color='secondary' href={URL.MARKETPLACE}>
          Let&apos;s go shopping
        </Button.Secondary>
      </StyledContainer>
    )
  }

  if (!totalResults && !isOwnProfile) {
    return (
      <StyledContainer>
        <Typography variant='h5' mb={3}>
          <DoggoEmptyState subtitle='No results found. Try Again.' />
        </Typography>
      </StyledContainer>
    )
  }

  return null
}
