'use client'

/* eslint-disable react/display-name */
import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'
import React from 'react'

const ScrollToTopRef = React.forwardRef<HTMLDivElement, { offset?: number }>(
  ({ offset = 16 }, ref) => {
    const { navHeight: navOffset } = useHeaderNavHeight()

    return (
      <div
        ref={ref}
        style={{ position: 'absolute', top: -(navOffset + offset) }}
      />
    )
  },
)

export default ScrollToTopRef
