import React from 'react'

import { Box } from '@mui/material'
import { Tooltip } from '@hubble/web'
import {
  GridCard,
  HubbleBadge,
  Link,
  Money,
  NiftyAssetProps,
  ProfileFullScreenModal,
  Typography,
  getAssetType,
} from '@/elements/src'

import useManageNiftiesOffers from '@/hooks/useManageNiftiesOffers'

import { ExternalNifty, ProfileNiftyV2 } from '@/types/global'
import { Currency } from '@/types/payment'
import { ProfileData } from '@/types/profile'

import { StyledContainerWithPadding } from '@/app/(ng-core)/(ng-homepage)/styles'
import CancelSaleModal from '@/app/(ng-core)/marketplace/_components/CancelSaleModal'
import TransferNiftyModal from '@/app/(ng-core)/marketplace/item/[contractAddress]/[tokenId]/_components/TransferNiftyModal'
import NftItemOptions from './NftItemOptions'
import ProfileAccepBidModal from '../ProfileAcceptBidModal'

interface ProfileNFTItemProps {
  isMobile?: boolean
  isOwnProfile: boolean
  item: ProfileData['items'][0]
  onClickItem?: (index?: number) => void
  onRefetch?: () => void
}

interface ExpandedNiftyProps {
  asset: NiftyAssetProps
  name: string
}

export default function ProfileNFTItem({
  isMobile,
  isOwnProfile,
  item,
  onClickItem,
  onRefetch,
}: ProfileNFTItemProps) {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [fullScreenOpen, setFullScreenOpen] = React.useState<boolean>(false)

  const [showSendModal, setShowSendModal] = React.useState<boolean>(false)
  const [showAcceptBidModal, setShowAcceptBidModal] =
    React.useState<boolean>(false)
  const [showCancelListingModal, setShowCancelListingModal] =
    React.useState<boolean>(false)
  const [expandedNifty, setExpandedNifty] =
    React.useState<ExpandedNiftyProps | null>(null)

  const externalItem = item as ExternalNifty
  const niftyItem = item as ProfileNiftyV2

  const niftyAsset = React.useMemo(
    () =>
      item.asset
        ? (item.asset as NiftyAssetProps)
        : {
            imageUrl: item.imageUrl || '',
            previewSrc: (item as ExternalNifty).amno?.imagePreviewUrl || '',
            src: item.imageUrl || '',
            staticOnly: true,
            type: getAssetType(item.imageUrl || ''),
          },
    [item],
  )

  const isExternal = Boolean((item as ExternalNifty).walletAddress)
  const isVerified = Boolean(
    isExternal
      ? (item as ExternalNifty).amno?.contractAddress && item.tokenId
      : item.contractAddress && item.tokenId,
  )

  const { data, isLoading: isLoadingOffers } = useManageNiftiesOffers(
    niftyItem,
    isOwnProfile && !isExternal,
  )
  const isThereAnActiveOffer = !!data?.highestOffer

  const getFloorPrice = () => {
    if (isExternal) {
      return externalItem.amno?.stats?.floorPriceInCents ?? 0
    }

    return niftyItem.stats?.floorPriceInCents ?? 0
  }

  const getTitle = React.useCallback(() => {
    if (isExternal)
      return (
        externalItem.name ||
        externalItem.amno?.projectName ||
        externalItem.tokenId
      )

    return niftyItem.name || niftyItem.projectName
  }, [isExternal, item])

  const getCardHref = () => {
    if (isVerified)
      return `/marketplace/item/${item.contractAddress}/${item.tokenId}`

    return ''
  }

  const handleExpandToFullScreen = React.useCallback(() => {
    setExpandedNifty({
      asset: niftyAsset,
      name: getTitle(),
    })
    setTimeout(() => setFullScreenOpen(true), 250)
  }, [getTitle, niftyAsset])

  const renderRow1Content = () => {
    return (
      <Box display='flex' flexDirection='column' gap='2px'>
        {isExternal ? (
          <>
            <Typography className='row1-content'>
              Collection:&nbsp;
              {(item as ExternalNifty).amno?.projectName ?? 'Unknown'}
            </Typography>
            <Tooltip
              disabled={isMobile}
              maxWidth={400}
              mouseEnterDelay={0}
              overlay={(item as ExternalNifty).walletAddress}
              placement='top'
            >
              <Typography className='row1-content'>
                Wallet:&nbsp;
                {(item as ExternalNifty).walletAddress}
              </Typography>
            </Tooltip>
          </>
        ) : (
          <>
            <Typography className='row1-content'>
              <span>
                Creator
                {niftyItem.creators && niftyItem.creators.length > 1 ? 's' : ''}
                :&nbsp;
                {(niftyItem.creators ?? []).map((creator, index) => (
                  <React.Fragment key={creator.id}>
                    {index > 0 && ', '}
                    <Link
                      href={`/${creator.storefrontSlug}`}
                      underline={false}
                      underlineOnHover
                    >
                      {creator.name.trim()}
                    </Link>
                  </React.Fragment>
                ))}
              </span>
            </Typography>
            {getFloorPrice() > 0 && (
              <Typography className='row1-content'>
                <Money
                  useUserPreference
                  value={[getFloorPrice(), Currency.USD]}
                />
                &nbsp;<span>Floor Price</span>
              </Typography>
            )}
          </>
        )}
      </Box>
    )
  }

  const renderRow2Content = () => {
    return !isExternal && isThereAnActiveOffer ? (
      <Box mt='6px'>
        <HubbleBadge
          color='default'
          label={
            <span>
              <Money
                useUserPreference
                value={[data?.highestOffer.priceInCents, Currency.USD]}
              />
              &nbsp;Best Offer
            </span>
          }
        />
      </Box>
    ) : undefined
  }

  const renderOptions = () => {
    return !isExternal ? (
      <NftItemOptions
        asset={niftyAsset}
        isMobile={isMobile}
        isOwnProfile={isOwnProfile}
        isThereAnActiveOffer={!isLoadingOffers && isThereAnActiveOffer}
        item={item}
        onAcceptBid={() => setShowAcceptBidModal(true)}
        onCancelListing={() => setShowCancelListingModal(true)}
        onCloseMenu={() => setMenuOpen(false)}
        onExpandToFullScreen={handleExpandToFullScreen}
        onOpenMenu={() => setMenuOpen(true)}
        onTransfer={() => setShowSendModal(true)}
      />
    ) : undefined
  }

  return (
    <>
      <StyledContainerWithPadding style={{ padding: 0 }}>
        <GridCard
          baseChain={niftyItem.chain}
          hideTooltip={isMobile}
          href={getCardHref()}
          mediaSrc={niftyAsset.src!}
          htmlSrc={niftyAsset.htmlSource}
          onClick={() => onClickItem?.()}
          persistTopRightCTA={menuOpen}
          row1Content={renderRow1Content()}
          row2Content={renderRow2Content()}
          title={getTitle()}
          topRightCTA={renderOptions()}
        />
      </StyledContainerWithPadding>

      {showCancelListingModal && (
        <CancelSaleModal
          contractAddress={item.contractAddress}
          handleClose={() => setShowCancelListingModal(false)}
          niftyTitle={getTitle()}
          onSuccess={onRefetch}
          open={showCancelListingModal}
          tokenId={item.tokenId}
        />
      )}

      {showSendModal && (
        <TransferNiftyModal
          contractAddress={item.contractAddress}
          handleClose={() => setShowSendModal(false)}
          name={getTitle()}
          open={showSendModal}
          tokenId={item.tokenId}
        />
      )}

      {expandedNifty && (
        <ProfileFullScreenModal
          asset={expandedNifty.asset}
          isOpen={fullScreenOpen}
          isOwnProfile={isOwnProfile}
          onClose={() => {
            setExpandedNifty(() => {
              setFullScreenOpen(false)

              return null
            })
          }}
          title={expandedNifty.name}
        />
      )}

      {showAcceptBidModal && (
        <ProfileAccepBidModal
          bidOrder={data?.highestOffer!}
          open={showAcceptBidModal}
          onRefetch={onRefetch}
          onClose={() => setShowAcceptBidModal(false)}
        />
      )}
    </>
  )
}
