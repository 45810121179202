import React from 'react'

import Confetti from 'react-confetti'

import { Box, Typography } from '@mui/material'
import { IconCheckCircleFilled } from '@hubble/icons'
import { colors, GlobalBidModal, TwoFAModal } from '@/elements/src'

import useDialog from '@/hooks/useDialog'
import { useTwoFAPreferences } from '@/hooks/useTwoFAPreferences'
import { userAcceptBid } from '@/api/offers'

import { BidOrder } from '@/types/global'
import formatAmountForDisplay from '@/utils/format-amount-for-display'

interface ProfileAccepBidModalProps {
  bidOrder: BidOrder
  onClose?: () => void
  onRefetch?: () => void
  open: boolean
}

export default function ProfileAccepBidModal({
  bidOrder,
  onClose,
  onRefetch,
  open,
}: ProfileAccepBidModalProps) {
  const { openDialog, RenderDialog } = useDialog()
  const { twoFAPreferences } = useTwoFAPreferences()

  const [showConfetti, setShowConfetti] = React.useState(false)
  // Global offer state
  const [offerState, setOfferState] = React.useState<{
    acceptGlobalOffer: boolean
    globalBidOrder: BidOrder | null
    offerId: string | null
    waitingForTwoFA: boolean
    isConfettiVisible: boolean
    acceptingOffer: boolean
  }>({
    acceptGlobalOffer: false,
    globalBidOrder: null,
    offerId: null,
    waitingForTwoFA: false,
    isConfettiVisible: false,
    acceptingOffer: false,
  })

  const submitAcceptOfferFunction = async (id: string) => {
    try {
      if (!twoFAPreferences?.nifty_leaves_account_one_touch) {
        setOfferState((prevState) => ({
          ...prevState,
          acceptingOffer: true,
        }))
        openDialog(
          <Typography fontSize='24px' fontWeight={600}>
            Accepting offer, plase wait...
          </Typography>,
        )
      }

      await userAcceptBid({
        bid_id: id,
        contractAddress: null,
        tokenId: null,
      })

      // Show confetti / remove twofa wait
      setShowConfetti(true)
      setOfferState((prevState) => ({
        ...prevState,
        isConfettiVisible: true,
        waitingForTwoFA: false,
        acceptingOffer: false,
      }))

      // Success prompt
      openDialog(
        <Box gap={1} display='flex' flexDirection='row' alignItems='center'>
          <IconCheckCircleFilled
            width={40}
            height={40}
            color={colors.darkGreen}
          />
          <Typography fontSize='24px' fontWeight={600}>
            Offer successfully accepted!
          </Typography>
        </Box>,
      )
      onRefetch?.()
      onClose?.()
    } catch (err) {
      openDialog('Error - Offer was unable to be accepted')
      onRefetch?.()
      onClose?.()

      setOfferState((prevState) => ({
        ...prevState,
        waitingForTwoFA: false,
        acceptingOffer: false,
      }))
    }
  }

  const handleSubmitAcceptOffer = async (id: string | null) => {
    if (twoFAPreferences?.nifty_leaves_account_one_touch) {
      setOfferState((prevState) => ({
        ...prevState,
        offerId: id,
        waitingForTwoFA: true,
      }))
    } else {
      submitAcceptOfferFunction(id as string)
    }
  }

  const handleAcceptOffer = React.useCallback(
    (bidOrderAccepted: BidOrder) => {
      if (bidOrderAccepted.AlreadyMintedNifty) {
        handleSubmitAcceptOffer(bidOrderAccepted.id.toString())
      } else if (bidOrderAccepted.UnmintedBuilderNifty) {
        setOfferState((prevState) => ({
          ...prevState,
          acceptGlobalOffer: true,
          globalBidOrder: bidOrderAccepted,
        }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOfferState, twoFAPreferences, offerState],
  )

  React.useEffect(
    () => {
      if (open)
        openDialog(
          <Box>
            <Typography fontSize='24px' fontWeight={600}>
              Confirm
            </Typography>
            <Typography mt={1} padding={2}>
              Are you sure you want to accept this offer of&nbsp;
              {formatAmountForDisplay(bidOrder.priceInCents / 100)}?
            </Typography>
          </Box>,
          () => handleAcceptOffer(bidOrder),
          'Yes',
          () => onClose?.(),
        )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  )

  return (
    <>
      <GlobalBidModal
        open={offerState.acceptGlobalOffer}
        onClose={() =>
          setOfferState((prevState) => ({
            ...prevState,
            acceptGlobalOffer: false,
          }))
        }
        twofaEnabled={twoFAPreferences?.nifty_leaves_account_one_touch ?? false}
        bidOrder={offerState.globalBidOrder}
      />

      {showConfetti && (
        <Confetti
          numberOfPieces={1000}
          tweenDuration={7500}
          recycle={false}
          width={window.innerWidth}
          height={window.innerHeight + window.scrollY}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}

      <TwoFAModal
        onSuccess={() => {
          submitAcceptOfferFunction(offerState.offerId as string)
        }}
        open={offerState.waitingForTwoFA}
      />

      <RenderDialog loading={offerState.acceptingOffer} />
    </>
  )
}
