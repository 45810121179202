import React from 'react'
import { Grid, Pagination, styled } from '@mui/material'

import { useProfileContext } from '@/providers/ProfileContext'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useCarousel } from '@/elements/src'

import GalleryCarouselItem from './gallery/CarouselItem'

const Container = styled('div')(() => ({
  left: '50%',
  marginBottom: 30,
  maxWidth: 1680,
  position: 'absolute',
  transform: 'translateX(-50%)',
  width: '100vw',
}))

const Scroller = styled(Grid)(() => ({
  overflowX: 'scroll',
  scrollSnapType: 'x proximity',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    width: 0,
    display: 'none',
  },
}))

const CarouselItemWrapper = styled(Grid)(({ theme }) => ({
  padding: '0 20px',
  scrollSnapAlign: 'start',
  scrollSnapStop: 'always',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface FrameViewProps {
  onItemClick: (index: number) => void
}

const GalleryView = ({ onItemClick }: FrameViewProps) => {
  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  const {
    data: {
      loading,
      items,
      totalPages,
      totalResults,
      currentPage,
      onChangePagination,
      onRefetch,
    },
    isOwnProfile,
  } = useProfileContext()
  const {
    containerRef,
    scrollerRef,
    itemRef,
    handleContentScroll,
    increment,
    decrement,
    activeItem,
  } = useCarousel({
    steps: 1,
    spacing: 0,
    horizontalPadding: 0,
    itemsLength: totalResults,
  })

  const handleClickItem = (itemIndex: number) => {
    const isActiveItem = itemIndex === activeItem

    if (isActiveItem) {
      onItemClick(itemIndex)
    } else if (activeItem > itemIndex) {
      decrement()
    } else {
      increment()
    }
  }

  return (
    <Container ref={containerRef}>
      <Scroller
        container
        wrap='nowrap'
        ref={scrollerRef}
        onScroll={handleContentScroll}
      >
        {items.map((item, index: number) => (
          <CarouselItemWrapper
            item
            key={`${item.contractAddress || index}.${item.tokenId || index}`}
            ref={itemRef}
          >
            <GalleryCarouselItem
              isMobile={isMobile ?? false}
              isOwnProfile={isOwnProfile}
              item={item}
              onClick={(e) => {
                if (index !== activeItem) {
                  e.preventDefault()
                }
                handleClickItem(index)
              }}
              onRefetch={() => onRefetch()}
              scrollContainerRef={scrollerRef}
            />
          </CarouselItemWrapper>
        ))}
        {loading &&
          new Array(3).fill(null).map((_, index) => (
            <CarouselItemWrapper
              item
              key={`skeleton-loader-${index}`}
              ref={itemRef}
            >
              <GalleryCarouselItem
                isLoading
                isMobile={isMobile ?? false}
                isOwnProfile={isOwnProfile}
                scrollContainerRef={scrollerRef}
              />
            </CarouselItemWrapper>
          ))}
      </Scroller>
      {totalPages > 1 ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: '24px 0',
          }}
        >
          <Pagination
            onChange={(_, page) => onChangePagination(page)}
            page={currentPage}
            count={totalPages}
            color='primary'
          />
        </div>
      ) : null}
    </Container>
  )
}

export default GalleryView
