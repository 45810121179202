import { cardFormat } from './card-format'

const formatPaymentMethod = (
  paymentType: string,
  cardType: string | null = null,
  cardLastFour: string | null = null,
) => {
  switch (paymentType) {
    case 'prepaid_eth':
      return 'Prepaid ETH'
    case 'balance':
      return 'Balance'
    case 'card':
      if (cardType && cardLastFour) {
        return cardFormat(cardType, cardLastFour)
      }
      return 'Card'
    default:
      return 'Prepaid ETH'
  }
}
export default formatPaymentMethod
