import { Box, styled } from '@mui/material'
import { NiftyAsset, Typography, colors, getAssetType } from '@/elements/src'
import { ProfileNiftyV2 } from '@/types/global'
import formatAmountForDisplay from '@/utils/format-amount-for-display'

const StyledNifty = styled(Box)({
  border: `1px solid ${colors.actionGrey}`,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '24px',
  '& > .MuiBox-root:first-child': {
    width: '200%',
  },
  '& .MuiBox-root:last-child': {
    borderRight: 'none',
  },
})

const StyledNiftyCell = styled(Box)({
  borderRight: `1px solid ${colors.actionGrey}`,
  overflow: 'hidden',
  padding: 10,
  width: '100%',
})

const StyledNiftyContainer = styled(Box)({
  borderRight: `1px solid ${colors.actionGrey}`,
  overflow: 'hidden',
  padding: 10,
  width: '100%',
})

const StyledNiftyContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
})

const StyledNiftyCellLabel = styled(Typography)({
  color: colors.grayText2,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
})

const StyledNiftyCellContent = styled(Typography)({
  color: colors.niftyBlack,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

const StyledNiftyAssetContainer = styled(Box)({
  borderRadius: 5,
  height: 40,
  minHeight: 40,
  minWidth: 40,
  overflow: 'hidden',
  position: 'relative',
  width: 40,
})

interface NiftyOffersNFTCellProps {
  label: string
  content: string
  children?: React.ReactNode
}

interface ManageNiftyOffersNFTProps {
  nifty: ProfileNiftyV2
}

function NiftyOffersNFTCell({
  label,
  content,
  children,
}: NiftyOffersNFTCellProps) {
  return (
    <StyledNiftyCell>
      <StyledNiftyContainer>
        {children}
        <StyledNiftyContentContainer>
          <StyledNiftyCellLabel>{label}</StyledNiftyCellLabel>
          <StyledNiftyCellContent>{content}</StyledNiftyCellContent>
        </StyledNiftyContentContainer>
      </StyledNiftyContainer>
    </StyledNiftyCell>
  )
}

function ManageNiftyOffersNFT({ nifty }: ManageNiftyOffersNFTProps) {
  const isKnownOriginalPrice = nifty.stats?.origPriceInCents !== 0
  const sellOrder = nifty.sellOrder?.[0]
  const isNiftyListedForSale = sellOrder?.currentlyActive

  return (
    <StyledNifty>
      <NiftyOffersNFTCell label='NFT' content={nifty.name}>
        <StyledNiftyAssetContainer>
          <NiftyAsset
            type={getAssetType(nifty.imageUrl)}
            src={nifty.imageUrl}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </StyledNiftyAssetContainer>
      </NiftyOffersNFTCell>
      <NiftyOffersNFTCell
        label='Creator'
        content={
          nifty.creators?.map((creator) => creator.name).join(', ') ?? ''
        }
      />
      <NiftyOffersNFTCell
        label='Purchase Price'
        content={
          isKnownOriginalPrice
            ? formatAmountForDisplay(
                (nifty.currentOwnerPurchasePriceInCents ?? 0) / 100,
              )
            : '--'
        }
      />
      <NiftyOffersNFTCell
        label='Floor Price'
        content={
          nifty.stats?.floorPriceInCents
            ? formatAmountForDisplay(
                (nifty.stats?.floorPriceInCents ?? 0) / 100,
              )
            : '--'
        }
      />
      <NiftyOffersNFTCell
        label='Last Sale'
        content={formatAmountForDisplay(
          (nifty.stats?.lastSaleAmountInCents ?? 0) / 100,
        )}
      />
      <NiftyOffersNFTCell
        label='Avg. Resale'
        content={formatAmountForDisplay(
          (nifty.stats?.averageSecondaryMarketSalePriceInCents ?? 0) / 100,
        )}
      />
      <NiftyOffersNFTCell
        label='Listing Price'
        content={
          isNiftyListedForSale
            ? formatAmountForDisplay((sellOrder?.priceInCents ?? 0) / 100)
            : '--'
        }
      />
    </StyledNifty>
  )
}

export default ManageNiftyOffersNFT
