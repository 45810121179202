import {
  ApiMarketplaceMintedNiftyResponse,
  ApiResponse,
  BasicResponse,
  MarketplaceMintedNiftyParams,
  NiftyEditionHistoryConfig,
  NiftyEditionHistoryResponse,
  NiftyMetaDataMintedConfig,
  NiftyMetaDataMintedResponse,
  SendNiftyData,
} from "@/types/api_types";
import { fetchApi } from "@/utils/api";

export const getAlreadyMintedNifties = async (
  data: MarketplaceMintedNiftyParams
): Promise<ApiResponse<ApiMarketplaceMintedNiftyResponse>> => {
  const results = await fetchApi({
    path: "/already_minted_nifties/",
    queryParams: { ...data.params },
    defaultHeader: {},
  });

  return { data: results };
};

export const getNiftyMetadataMinted = async (
  params: NiftyMetaDataMintedConfig
): Promise<ApiResponse<NiftyMetaDataMintedResponse>> => {
  const results = await fetchApi({
    path: "/nifty/metadata-minted/",
    queryParams: { ...params },
  });

  return { data: results };
};

export const getMarketNiftyEditionHistory = async (
  params: NiftyEditionHistoryConfig
): Promise<ApiResponse<NiftyEditionHistoryResponse>> => {
  const results = await fetchApi({
    path: "/marketplace/nifty-history-by-edition/",
    method: "GET",
    queryParams: { ...params },
  });

  return { data: results };
};

export const sendNiftyToAnotherUser = async (
  data: SendNiftyData
): Promise<ApiResponse<BasicResponse>> => {
  const results = await fetchApi({
    path: "/user/send-nifty-to-another-user/",
    method: "POST",
    body: JSON.stringify(data),
  });

  return { data: results };
};

export const delistCustodialNifty = async (data: {
  contractAddress: string | null;
  tokenId: string;
}): Promise<ApiResponse<BasicResponse>> => {
  const results = await fetchApi({
    path: "/user/delist-centralized-nifty/",
    method: "POST",
    body: JSON.stringify(data),
  });

  return { data: results };
};

export const delistListing = async (
  listingId: string
): Promise<ApiResponse<BasicResponse>> => {
  const results = await fetchApi({
    path: `/v2/listing/${listingId}/deactivate/`,
    method: "PATCH",
  });

  return { data: results };
};

export const cancelListingV2 = async (
  listingId: string
): Promise<ApiResponse<BasicResponse>> => {
  const results = await fetchApi({
    path: `/v2/listing/${listingId}/`,
    method: "DELETE",
  });

  return { data: results };
};
