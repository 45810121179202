import React from 'react'
import { formatDistanceStrict, formatDistanceToNowStrict } from 'date-fns'

import { Box, List, Typography, styled } from '@mui/material'
import { IconInfoOutlined } from '@hubble/icons'
import { HubbleButton, Link } from '@/elements/src'

import formatAmountForDisplay from '@/utils/format-amount-for-display'
import formatPaymentMethod from '@/utils/format-payment-method'
import getBidOrderTypeText from '@/utils/get-bid-order-type-text'

import { BidOrder } from '@/types/global'

import ManageNiftiesModalDetailRow from './NiftiesModalDetailRow'
import { WEEK_IN_MILISECONDS } from '../../constants'

const StyledTruncatedText = styled(Typography)({
  lineClamp: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxOrient: 'vertical',
  textOverflow: 'ellipsis',
})

const StyledButtonsContainer = styled(Box)({
  gap: 12,
  display: 'flex',
  marginTop: '16px',
  flexDirection: 'row',
})

const StyledSemiboldText = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
})

const StyledTotalAfterFeesContainer = styled(Box)({
  display: 'flex',
  padding: '16px 0px',
  flexDirection: 'row',
})

interface NiftyOfferDetailProps {
  offer: BidOrder
  isBestOffer?: boolean
  handleOnClickFeesBreakdown: () => void
  onAcceptBid: (bidOrder: BidOrder) => void
}

function ManageNiftyOfferDetail({
  offer,
  isBestOffer,
  onAcceptBid,
  handleOnClickFeesBreakdown,
}: NiftyOfferDetailProps) {
  const nowDate = Date.now()
  const bidExpirationDate = offer
    ? new Date(offer.Timestamp!).setMilliseconds(WEEK_IN_MILISECONDS)
    : Date.now()

  if (!offer) return null

  return (
    <>
      <List sx={{ padding: 0 }}>
        <ManageNiftiesModalDetailRow
          label={<StyledSemiboldText>Offer</StyledSemiboldText>}
          value={
            <StyledSemiboldText textAlign='right'>
              {formatAmountForDisplay((offer.priceInCents ?? 0) / 100)}
            </StyledSemiboldText>
          }
        />
        <ManageNiftiesModalDetailRow
          label={
            <StyledTotalAfterFeesContainer alignItems='center'>
              <StyledSemiboldText>Total After Fees</StyledSemiboldText>
              <HubbleButton
                isIconButton
                size='small'
                variant='tertiary'
                onClick={() => handleOnClickFeesBreakdown()}
              >
                <IconInfoOutlined width={16} height={16} />
              </HubbleButton>
            </StyledTotalAfterFeesContainer>
          }
          value={
            <StyledTotalAfterFeesContainer justifyContent='flex-end'>
              <StyledSemiboldText>
                {formatAmountForDisplay(
                  (offer.amountIfSoldDict?.total_amount_seller_would_receive ??
                    0) / 100,
                )}
              </StyledSemiboldText>
            </StyledTotalAfterFeesContainer>
          }
        />
        <ManageNiftiesModalDetailRow
          label='Offer From'
          value={
            <StyledTruncatedText
              fontSize={16}
              fontWeight={400}
              textAlign='right'
            >
              <Link
                openInNewTab
                underline={false}
                href={`/profile/${offer.userMakingOffer.profile_url}`}
              >
                {offer.userMakingOffer.name}
              </Link>
            </StyledTruncatedText>
          }
        />
        <ManageNiftiesModalDetailRow
          label='Bid Type'
          value={getBidOrderTypeText(offer.BuyOrderPurchaseType)}
        />
        <ManageNiftiesModalDetailRow
          label='Payment'
          value={formatPaymentMethod(offer.paymentType!)}
        />
        <ManageNiftiesModalDetailRow
          label='Offer Date'
          value={formatDistanceToNowStrict(new Date(offer.Timestamp!), {
            addSuffix: true,
          })}
        />
        <ManageNiftiesModalDetailRow
          label='Expires In'
          value={
            nowDate > bidExpirationDate
              ? 'Expired'
              : formatDistanceStrict(nowDate, bidExpirationDate)
          }
        />
      </List>

      <StyledButtonsContainer>
        <HubbleButton variant='primary' onClick={() => onAcceptBid?.(offer)}>
          {isBestOffer ? 'Accept Best Offer' : 'Accept'}
        </HubbleButton>
      </StyledButtonsContainer>
    </>
  )
}

export default ManageNiftyOfferDetail
