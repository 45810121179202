import React from 'react'
import { Box, Typography } from '@mui/material'
import { BidOrder, ProfileNiftyV2 } from '@/types/global'
import {
  HubbleTable,
  HubbleTableAssetCell,
  HubbleTableRowProps,
  HubbleTableTitleCell,
  ImageTransformPreset,
  Money,
  colors,
  getAssetType,
  useHubbleTableHideByWidth,
} from '@/elements/src'
import formatAmountForDisplay from '@/utils/format-amount-for-display'
import {
  MANAGE_NIFTIES_COLUMNS,
  MANAGE_NIFTIES_PAGE_SIZE,
} from '../../constants'
import useManageNifties from '@/hooks/useManageNifties'
import CancelSaleModal from '@/app/(ng-core)/marketplace/_components/CancelSaleModal'
import TransferNiftyModal from '@/app/(ng-core)/marketplace/item/[contractAddress]/[tokenId]/_components/TransferNiftyModal'
import ManageNiftiesOffersCell from './NiftiesOffersCell'
import ManageNiftiesMoreCell from './NiftiesMoreCell'
import { Currency } from '@/types/payment'

interface CreateManageNiftiesTableRowProps {
  isMobile: boolean
  item: ProfileNiftyV2
  onAcceptBid?: (bidOrder: BidOrder) => void
  onCancelSale?: () => void
  onExpandToFullScreen?: () => void
  onSelect?: () => void
  onSent?: () => void
}

function createManageNiftiesTableRow({
  isMobile,
  item,
  onAcceptBid,
  onCancelSale,
  onExpandToFullScreen,
  onSelect,
  onSent,
}: CreateManageNiftiesTableRowProps): HubbleTableRowProps<ProfileNiftyV2> {
  const isKnownOriginalPrice = item.stats?.origPriceInCents !== 0
  const sellOrder = item.sellOrder?.[0]
  const isNiftyListedForSale = !!item.listing || sellOrder?.currentlyActive

  return {
    item,
    id: item.id,
    clickable: true,
    basePath: isMobile ? undefined : '/marketplace/item/',
    path: isMobile ? undefined : `${item.contractAddress}/${item.tokenId}`,
    cells: {
      nft_creator: (
        <HubbleTableAssetCell
          asset={{
            alt: item.name,
            src: item.imageUrl,
            transform: ImageTransformPreset.thumb,
            type: getAssetType(item.imageUrl),
          }}
        >
          <Box display='flex' flexDirection='column' overflow='hidden'>
            <Typography
              fontSize='16px'
              variant='subtitle1'
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.name}
            </Typography>
            <Typography
              variant='body2'
              fontWeight={500}
              color={colors.grayText2}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.creators?.map((creator) => creator.name).join(', ')}
            </Typography>
          </Box>
        </HubbleTableAssetCell>
      ),
      purchase_price: (
        <HubbleTableTitleCell
          align='right'
          title={
            isKnownOriginalPrice
              ? formatAmountForDisplay(
                  (item.currentOwnerPurchasePriceInCents ?? 0) / 100,
                )
              : '--'
          }
        />
      ),
      floor_price: (
        <HubbleTableTitleCell
          align='right'
          title={
            item.stats?.floorPriceInCents
              ? formatAmountForDisplay(
                  (item.stats?.floorPriceInCents ?? 0) / 100,
                )
              : '--'
          }
        />
      ),
      last_sale: (
        <HubbleTableTitleCell
          align='right'
          title={formatAmountForDisplay(
            (item.stats?.lastSaleAmountInCents ?? 0) / 100,
          )}
        />
      ),
      avg_resale: (
        <HubbleTableTitleCell
          align='right'
          title={formatAmountForDisplay(
            (item.stats?.averageSecondaryMarketSalePriceInCents ?? 0) / 100,
          )}
        />
      ),
      listing_price: (
        <HubbleTableTitleCell
          align='right'
          title={
            isNiftyListedForSale ? (
              <Money
                convertFromCents={!item.listing?.amount}
                value={
                  item.listing?.amount
                    ? [item.listing.amount, item.listing.currency]
                    : [sellOrder?.priceInCents ?? 0, Currency.USD]
                }
              />
            ) : (
              '--'
            )
          }
        />
      ),
      offers: <ManageNiftiesOffersCell nifty={item} />,
      best_active_offer: <ManageNiftiesOffersCell nifty={item} isBestOffer />,
      offers_button: (
        <ManageNiftiesOffersCell
          nifty={item}
          isViewButton
          onSelect={onSelect}
        />
      ),
      list_button: (
        <ManageNiftiesOffersCell
          nifty={item}
          isListButton
          onCancelSale={onCancelSale}
          isNiftyListedForSale={isNiftyListedForSale}
        />
      ),
      more_button: (
        <ManageNiftiesMoreCell
          nifty={item}
          onSent={onSent}
          onAcceptBid={onAcceptBid}
          onExpandToFullScreen={onExpandToFullScreen}
        />
      ),
      offers_badge: <ManageNiftiesOffersCell isBadge nifty={item} />,
    },
  }
}

interface ManageNiftiesTableProps {
  isMobile: boolean
  isMobileRefetch: boolean
  onAcceptBid: (bidOrder: BidOrder, cb?: () => void) => void
  onItemClick?: () => void
  onExpandToFullScreen: (nifty: ProfileNiftyV2) => void
  onMobileRefecthCallback: () => void
  onSelect: (nifty: ProfileNiftyV2) => void
  profileUrl: string
  search: string
}

export default function ManageNiftiesTable({
  isMobile,
  isMobileRefetch,
  onAcceptBid,
  onItemClick,
  onExpandToFullScreen,
  onMobileRefecthCallback,
  onSelect,
  profileUrl,
  search,
}: ManageNiftiesTableProps) {
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [nifty, setNifty] = React.useState<ProfileNiftyV2 | null>(null)
  const [showSendModal, setShowSendModal] = React.useState<boolean>(false)
  const [showCancelListingModal, setShowCancelListingModal] =
    React.useState<boolean>(false)

  const hideColumnByWidth = useHubbleTableHideByWidth({
    columns: MANAGE_NIFTIES_COLUMNS,
  })

  const hideColumn = React.useCallback(
    (column: string) => {
      const header = MANAGE_NIFTIES_COLUMNS.find(
        (columnHeader) => columnHeader.id === column,
      )

      if (isMobile) return header?.hideOnMobile ?? false
      return header?.hideOnDesktop ?? hideColumnByWidth(column)
    },
    [isMobile, hideColumnByWidth],
  )

  const {
    items,
    totalItems,
    query: {
      isPreviousData,
      isLoading,
      isFetchingNextPage,
      isFetchingPreviousPage,
      refetch,
    },
  } = useManageNifties(search, profileUrl, {
    page: currentPage,
    page_size: MANAGE_NIFTIES_PAGE_SIZE,
  })

  const onRefetch = () => refetch()

  const onPagination = React.useCallback(
    (page: number) => setCurrentPage(page + 1),
    [],
  )

  const rows =
    items?.map((item) =>
      createManageNiftiesTableRow({
        item,
        isMobile,
        onAcceptBid: (bidOrder) => onAcceptBid(bidOrder, onRefetch),
        onExpandToFullScreen: () => onExpandToFullScreen(item),
        onSelect: () => onSelect(item),
        onSent: () => {
          setNifty(item)
          setShowSendModal(true)
        },
        onCancelSale: () => {
          setNifty(item)
          setShowCancelListingModal(true)
        },
      }),
    ) ?? []

  React.useEffect(() => {
    if (isMobileRefetch) {
      onRefetch()
      onMobileRefecthCallback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileRefetch])

  return (
    <>
      <HubbleTable
        rows={rows}
        smallPadding
        columns={MANAGE_NIFTIES_COLUMNS}
        hideColumn={hideColumn}
        isFetching={
          isLoading ||
          isPreviousData ||
          isFetchingNextPage ||
          isFetchingPreviousPage
        }
        onClickCallback={({ item }) => {
          onItemClick?.()
          if (isMobile) onSelect(item)
        }}
        pagination={{
          totalRows: totalItems,
          rowsPerPage: MANAGE_NIFTIES_PAGE_SIZE,
          currentPage: currentPage - 1,
          setCurrentPage: onPagination,
        }}
      />

      {showCancelListingModal && (
        <CancelSaleModal
          niftyTitle={nifty?.name!}
          tokenId={nifty?.tokenId!}
          contractAddress={nifty?.contractAddress!}
          open={showCancelListingModal}
          handleClose={() => {
            setNifty(null)
            setShowCancelListingModal(false)
          }}
          onSuccess={onRefetch}
        />
      )}

      <TransferNiftyModal
        name={nifty?.name!}
        tokenId={nifty?.tokenId!}
        contractAddress={nifty?.contractAddress!}
        open={showSendModal}
        handleClose={() => {
          setNifty(null)
          setShowSendModal(false)
        }}
      />
    </>
  )
}
