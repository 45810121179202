import { Filter } from '@/types/global'
import sum from 'lodash/sum'
import { ParsedQs } from 'qs'

// {immutablex: true, ethereum: true, binance: false} => "immutablex,ethereum"
export const filterObjectToString = (
  filterObject?: Record<string, boolean>,
) => {
  if (filterObject === undefined) {
    return 'ethereum,immutablex'
  }
  return Object.keys(filterObject)
    .filter((key) => filterObject[key])
    .toString()
}

export const parseFilters = (obj: ParsedQs | Filter): Filter => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === 'true' || newObj[key] === 'false') {
      newObj[key] = newObj[key] === 'true'
    } else if (typeof obj[key] === 'object' && !Array.isArray(newObj[key])) {
      newObj[key] = parseFilters(newObj[key] as ParsedQs) as ParsedQs
    }
  })
  return newObj
}

export const preserveScroll = (callback: () => void) => {
  const y = window.scrollY
  callback()
  requestAnimationFrame(() => {
    window.scroll(0, y)
  })
}

const accordionLocalStorage = {
  get: (id: string, defaultValue: boolean = true): boolean =>
    JSON.parse(global?.window?.localStorage?.getItem(id) || `${defaultValue}`),
  set: (id: string, value: boolean) => {
    global?.window?.localStorage?.setItem(id, value.toString())
  },
}

// For use w/ PriceFilterAccordion, FilterGroupAccordion + EditionFilterAccordion
export const expandedStateProps = (id: string, defaultValue?: boolean) => ({
  onToggle: (_e: React.ChangeEvent<{}>, v: boolean) =>
    accordionLocalStorage.set(id, v),
  defaultExpanded: accordionLocalStorage.get(id, defaultValue),
})

export const countActiveFilters = (
  filterState: Array<{ [key: string]: boolean | null } | unknown>,
) => {
  return sum(
    filterState.map((t) => Object.values(t as Object).filter(Boolean).length),
  )
}

export const setFilterGroupToTrue = (filterGroup: {
  [key: string]: string
}) => {
  const allChecked: { [key: string]: boolean } = {}
  // eslint-disable-next-line
  Object.keys(filterGroup).forEach((k) => (allChecked[k] = true))
  return allChecked
}
