import { IconMoreHorizontal } from '@hubble/icons'
import { HubbleMenu } from '@/elements/src'

import { BidOrder, ProfileNiftyV2 } from '@/types/global'

import useNiftyDownload from '@/hooks/useNFTDownload'
import useManageNiftiesOffers from '@/hooks/useManageNiftiesOffers'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { useCreateTokenWithdrawal } from '@/hooks/useTokenWithdrawal'

interface ManageNiftiesMoreCellProps {
  nifty: ProfileNiftyV2
  onAcceptBid?: (bidOrder: BidOrder) => void
  onExpandToFullScreen?: () => void
  onSent?: () => void
}

function ManageNiftiesMoreCell({
  nifty,
  onAcceptBid,
  onExpandToFullScreen,
  onSent,
}: ManageNiftiesMoreCellProps) {
  const { downloadNifty } = useNiftyDownload(nifty.imageUrl, nifty.name)
  const { data, isLoading } = useManageNiftiesOffers(nifty)

  const { logEvent } = useAnalytics()
  const { createTokenWithdrawal } = useCreateTokenWithdrawal()

  const thereIsAnActiveOffer = !!data?.highestOffer

  return (
    <HubbleMenu
      id='manage-more-action'
      label={<IconMoreHorizontal />}
      buttonProps={{
        isIconButton: true,
        variant: 'tertiary',
        type: 'button',
        size: 'small',
      }}
    >
      {!isLoading && thereIsAnActiveOffer && (
        <HubbleMenu.Item onClick={() => onAcceptBid?.(data?.highestOffer)}>
          Accept Best Offer
        </HubbleMenu.Item>
      )}
      <HubbleMenu.Item
        onClick={() => {
          onSent?.()
          logEvent?.('Transfer Click', 'Other')
        }}
      >
        Send
      </HubbleMenu.Item>
      <HubbleMenu.Item
        onClick={() =>
          createTokenWithdrawal({
            contract_address: nifty.contractAddress,
            token_id: nifty.tokenId,
          })
        }
      >
        Withdraw
      </HubbleMenu.Item>
      <HubbleMenu.Item onClick={() => downloadNifty()}>
        Download
      </HubbleMenu.Item>
      <HubbleMenu.Item onClick={onExpandToFullScreen}>
        Expand to Full Screen
      </HubbleMenu.Item>
    </HubbleMenu>
  )
}

export default ManageNiftiesMoreCell
