import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import {
  HubbleTable,
  HubbleTableAssetCell,
  HubbleTableRowProps,
  HubbleTableTitleCell,
  ImageTransformPreset,
  getAssetType,
} from '@/elements/src'
import { ExternalNifty } from '@/types/global'
import { useProfileContext } from '@/providers/ProfileContext'
import {
  MANAGE_EXTERNAL_NIFTIES_COLUMNS,
  MANAGE_NIFTIES_PAGE_SIZE,
} from '../../constants'
import ManageExternalNiftiesContractCell from './ExternalNiftiesContractCell'

interface CreateManageExternalNiftiesTableRowProps {
  isMobile: boolean
  item: ExternalNifty
}

function createManageExternalNiftiesTableRow({
  item,
  isMobile,
}: CreateManageExternalNiftiesTableRowProps): HubbleTableRowProps<ExternalNifty> {
  return {
    item,
    id: item.tokenId,
    cells: {
      nft_creator: (
        <HubbleTableAssetCell
          asset={{
            alt: item.name,
            src: item.imageUrl,
            transform: ImageTransformPreset.thumb,
            type: getAssetType(item.imageUrl),
          }}
        >
          <Box display='flex' flexDirection='column' overflow='hidden'>
            <Typography
              fontSize='16px'
              variant='subtitle1'
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.name || item.amno?.projectName || item.tokenId}
            </Typography>
          </Box>
        </HubbleTableAssetCell>
      ),
      collection: (
        <HubbleTableTitleCell title={item.amno?.projectName ?? 'Unknown'} />
      ),
      contract: (
        <ManageExternalNiftiesContractCell
          contractAddress={item.contractAddress}
        />
      ),
      wallet: <HubbleTableTitleCell title={item.walletAddress} />,
    },
  }
}

interface ManageExternalNiftiesTableProps {
  isMobile: boolean
  onItemClick?: () => void
}

export default function ManageExternalNiftiesTable({
  isMobile,
  onItemClick,
}: ManageExternalNiftiesTableProps) {
  const {
    data: { items, loading, currentPage, totalResults, onChangePagination },
    state: { wallet },
  } = useProfileContext()

  const hideColumn = useCallback(
    (column: string) => {
      const header = MANAGE_EXTERNAL_NIFTIES_COLUMNS.find(
        (columnHeader) => columnHeader.id === column,
      )

      if (isMobile)
        return (
          header?.hideOnMobile ||
          (wallet !== 'combinedWallets'
            ? header?.hideOnSingle
            : !(header?.hideOnSingle ?? true)) ||
          false
        )
      return wallet !== 'combinedWallets'
        ? header?.hideOnSingle ?? false
        : false
    },
    [isMobile, wallet],
  )

  const onPagination = useCallback(
    (page: number) => onChangePagination(page + 1),
    [onChangePagination],
  )

  const rows =
    items?.map((item) =>
      createManageExternalNiftiesTableRow({
        item: item as ExternalNifty,
        isMobile,
      }),
    ) ?? []

  return (
    <HubbleTable
      rows={rows}
      smallPadding
      columns={MANAGE_EXTERNAL_NIFTIES_COLUMNS}
      hideColumn={hideColumn}
      isFetching={loading}
      onClickCallback={() => onItemClick?.()}
      pagination={{
        totalRows: totalResults,
        rowsPerPage: MANAGE_NIFTIES_PAGE_SIZE,
        currentPage: currentPage - 1,
        setCurrentPage: onPagination,
      }}
    />
  )
}
