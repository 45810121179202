import { Box, List, styled } from '@mui/material'
import { HubbleButton } from '@/elements/src'

import formatAmountForDisplay from '@/utils/format-amount-for-display'
import { getListingUrl } from '@/utils/get-links'

import { ProfileNiftyV2 } from '@/types/global'

import ManageNiftiesModalDetailRow from './NiftiesModalDetailRow'

const StyledButtonsContainer = styled(Box)({
  gap: 12,
  display: 'flex',
  marginTop: '16px',
  flexDirection: 'row',
})

interface ManageNiftyDetailsProps {
  nifty: ProfileNiftyV2
  handleOnClickListSale: () => void
  handleOnClickMoreOptions: () => void
}

function ManageNiftyDetails({
  nifty,
  handleOnClickListSale,
  handleOnClickMoreOptions,
}: ManageNiftyDetailsProps) {
  const isKnownOriginalPrice = nifty.stats?.origPriceInCents !== 0
  const sellOrder = nifty.sellOrder?.[0]
  const isNiftyListedForSale = sellOrder?.currentlyActive

  return (
    <>
      <List sx={{ padding: 0 }}>
        <ManageNiftiesModalDetailRow
          label='Creator'
          value={
            nifty.creators?.map((creator) => creator.name).join(', ') ?? ''
          }
        />
        <ManageNiftiesModalDetailRow
          label='Purchase Price'
          value={
            isKnownOriginalPrice
              ? formatAmountForDisplay(
                  (nifty.currentOwnerPurchasePriceInCents ?? 0) / 100,
                )
              : '--'
          }
        />
        <ManageNiftiesModalDetailRow
          label='Floor Price'
          value={
            nifty.stats?.floorPriceInCents
              ? formatAmountForDisplay(
                  (nifty.stats?.floorPriceInCents ?? 0) / 100,
                )
              : '--'
          }
        />
        <ManageNiftiesModalDetailRow
          label='Avg. Resale'
          value={formatAmountForDisplay(
            (nifty.stats?.averageSecondaryMarketSalePriceInCents ?? 0) / 100,
          )}
        />
        <ManageNiftiesModalDetailRow
          label='Listing Price'
          value={
            isNiftyListedForSale
              ? formatAmountForDisplay((sellOrder?.priceInCents ?? 0) / 100)
              : '--'
          }
        />
      </List>

      <StyledButtonsContainer>
        <HubbleButton
          openInNewTab
          variant='primary'
          sx={{ width: '100%' }}
          href={
            !isNiftyListedForSale
              ? getListingUrl(nifty.contractAddress, nifty.tokenId)
              : undefined
          }
          onClick={() => {
            if (isNiftyListedForSale) handleOnClickListSale()
          }}
        >
          {isNiftyListedForSale ? 'Unlist For Sale' : 'List For Sale'}
        </HubbleButton>

        <HubbleButton
          variant='tertiary'
          sx={{ width: '100%' }}
          onClick={(event) => {
            event.stopPropagation()
            handleOnClickMoreOptions()
          }}
        >
          More Actions
        </HubbleButton>
      </StyledButtonsContainer>
    </>
  )
}

export default ManageNiftyDetails
