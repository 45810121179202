import { formatDistanceStrict, formatDistanceToNowStrict } from 'date-fns'
import { Box, Typography } from '@mui/material'
import { IconInfoOutlined } from '@hubble/icons'
import {
  HubbleButton,
  HubbleTable,
  HubbleTableListLinkCell,
  HubbleTableRowProps,
  HubbleTableTitleCell,
} from '@/elements/src'

import formatAmountForDisplay from '@/utils/format-amount-for-display'
import getBidOrderTypeText from '@/utils/get-bid-order-type-text'
import formatPaymentMethod from '@/utils/format-payment-method'

import { BidOrder } from '@/types/global'
import { NIFTY_OFFERS_COLUMNS, WEEK_IN_MILISECONDS } from '../../constants'

interface CreateManageNiftyOffersTableRowProps {
  item: BidOrder
  index: number
  onAcceptBid: (bidOrder: BidOrder, cb: () => void) => void
  onClickFeesBreakdown: (bidOrder: BidOrder) => void
}

function createManageNiftyOffersTableRow({
  item,
  index,
  onAcceptBid,
  onClickFeesBreakdown,
}: CreateManageNiftyOffersTableRowProps): HubbleTableRowProps<BidOrder> {
  const nowDate = Date.now()
  const bidExpirationDate = new Date(item.Timestamp).setMilliseconds(
    WEEK_IN_MILISECONDS,
  )

  return {
    item,
    id: item.id,
    cells: {
      offer_from: (
        <HubbleTableListLinkCell
          basePath='/profile/'
          paths={[
            {
              path: item.userMakingOffer.profile_url,
              label: item.userMakingOffer.name,
            },
          ]}
        />
      ),
      offer: (
        <HubbleTableTitleCell
          align='right'
          title={formatAmountForDisplay(item.priceInCents / 100)}
        />
      ),
      total_after_fees: (
        <Box
          display='flex'
          alignItems='center'
          flexDirection='row'
          justifyContent='flex-end'
        >
          <Typography component='span' fontSize='16px'>
            {formatAmountForDisplay(
              (item.amountIfSoldDict?.total_amount_seller_would_receive ?? 0) /
                100,
            )}
          </Typography>
          <HubbleButton
            isIconButton
            size='small'
            variant='tertiary'
            onClick={() => onClickFeesBreakdown(item)}
          >
            <IconInfoOutlined width={16} height={16} />
          </HubbleButton>
        </Box>
      ),
      bid_type: (
        <HubbleTableTitleCell
          title={getBidOrderTypeText(item.BuyOrderPurchaseType)}
        />
      ),
      payment: (
        <HubbleTableTitleCell title={formatPaymentMethod(item.paymentType)} />
      ),
      offer_date: (
        <HubbleTableTitleCell
          title={formatDistanceToNowStrict(new Date(item.Timestamp), {
            addSuffix: true,
          })}
        />
      ),
      expiration: (
        <HubbleTableTitleCell
          title={
            nowDate > bidExpirationDate
              ? 'Expired'
              : formatDistanceStrict(nowDate, bidExpirationDate)
          }
        />
      ),
      button: (
        <Box
          py={1.5}
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          <HubbleButton
            size='small'
            variant={index === 0 ? 'primary' : 'secondary'}
            onClick={() => onAcceptBid(item, () => {})}
          >
            {index === 0 ? 'Accept Best Offer' : 'Accept'}
          </HubbleButton>
        </Box>
      ),
    },
  }
}

interface ManageNiftyOfferTableProps {
  items: BidOrder[]
  onAcceptBid: (bidOrder: BidOrder, cb: () => void) => void
  onClickFeesBreakdown: (bidOrder: BidOrder) => void
}

export default function ManageNiftyOfferTable({
  items,
  onAcceptBid,
  onClickFeesBreakdown,
}: ManageNiftyOfferTableProps) {
  const rows = items.map((item, index) =>
    createManageNiftyOffersTableRow({
      item,
      index,
      onAcceptBid,
      onClickFeesBreakdown,
    }),
  )

  return <HubbleTable rows={rows} columns={NIFTY_OFFERS_COLUMNS} />
}
