import { useQuery } from '@tanstack/react-query'

import { getUserNiftyOffers } from '@/api/profile'

import { ProfileNiftyV2 } from '@/types/global'

const useManageNiftiesOffers = (item: ProfileNiftyV2, enabled?: boolean) => {
  const queryEnabled =
    typeof enabled === 'boolean'
      ? enabled
      : Boolean(item.tokenId && item.contractAddress)

  const query = useQuery(
    ['profileManageNiftyHighestOffer', item.tokenId, item.contractAddress],
    async () => {
      const response = await getUserNiftyOffers({
        size: 50,
        current: 1,
        filter: {
          contractAddress: item.contractAddress,
          tokenId: item.tokenId,
        },
      })

      const {
        data: { results, meta },
      } = response
      return {
        offers: results,
        highestOffer: results[0],
        totalOffers: meta.page.total_results,
      }
    },
    {
      enabled: queryEnabled,
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useManageNiftiesOffers
